const OutlineCar = ({ fill, width, height }) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 60 60"
                height={height}
                width={width}
                fill={fill}
            >
                <g>
                    <path d="M24.14,41.13H37.77a4.24,4.24,0,0,1,4.13-4,4.17,4.17,0,0,1,4.13,4h1.68v-.34a7.92,7.92,0,0,0-.38-2.45l-.24-.67c-.39-1-1-1.54-2.64-1.92l-2.4-.53A7.26,7.26,0,0,1,40,34.41a19.77,19.77,0,0,0-5-2.5c-1.92-.62-5.81-.77-9.21-.77-2.07,0-4.42.15-6.63.29l-2.4-1.3v-.57c1.92-.15,6.77-.43,9.55-.43,3.12,0,7.44.19,9.56.91a27.16,27.16,0,0,1,5,2.49,6.52,6.52,0,0,0,1.44.63l2.4.57c1.58.39,2.83.82,3.5,1.68a8.86,8.86,0,0,1,1.64,4.66c0,2.35-.68,3.07-2.45,3.07H45.69a4.16,4.16,0,0,1-7.58,0H23.81A4.18,4.18,0,0,1,20,45.59a4.08,4.08,0,0,1-4-3.12c-2.21-.1-3.27-.81-3.27-3.46V34.89a26.7,26.7,0,0,1,3.17-3.56l1.83,1a31.8,31.8,0,0,0-3,3.22v3.6c0,1,.39,1.25,1.25,1.25a4.25,4.25,0,0,1,4-3.27A4.17,4.17,0,0,1,24.14,41.13Zm-2,.24a2.16,2.16,0,1,0-4.32,0,2.16,2.16,0,1,0,4.32,0Zm21.89,0a2.16,2.16,0,1,0-4.32,0,2.16,2.16,0,1,0,4.32,0Z" />
                </g>
            </svg>
        </>
    );
};

export default OutlineCar;

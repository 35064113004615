import Image from "next/dist/client/image";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { formHelpers } from "../../../../lib/formHelpers";
import { IDealerListData } from "../../../../lib/queries/DealerQuery";
import { TestDriveCarInfo } from "../../../../lib/queries/TestDriveCarInfoQuery";
import Footer from "../../../Footer/Footer";
import FooterModal from "../../../FooterModal";
import TopBanner from "../../TopBanner";
import FormComponent from "./FormComponent";

interface IVideoViewingForm {
    showModal: boolean;
    setShowModal: React.SetStateAction<boolean>;
    data: IDealerListData;
    carData?: TestDriveCarInfo;
}

const VideoViewingForm = ({
    showModal,
    setShowModal,
    data,
    carData,
}: IVideoViewingForm) => {
    const [postNumber, setPostNumber] = useState<string>();
    const fullDealersList = data;
    const [filteredDealers, setFilteredDealers] = useState() as any[];
    const [selectedDealerForm, setSelectedDealerForm] = useState(null);
    const [dealerPreviouslySelected, setDealerPreviouslySelected] =
        useState(false);

    useEffect(() => {
        if (postNumber) {
            const filteredDealers = formHelpers.getPostalNumber(
                postNumber,
                fullDealersList
            );
            setFilteredDealers(filteredDealers);
            setDealerPreviouslySelected(true);
        }
    }, [postNumber, fullDealersList]);

    return (
        <>
            <TopBanner
                title={`Avtal en videovisning`}
                showModal={showModal}
                setShowModal={setShowModal}
            />
            <ContentWrapper>
                {carData.testDriveImage && (
                    <Background>
                        <Image
                            unoptimized={true}
                            src={carData.testDriveImage}
                            layout="fill"
                            objectFit="cover"
                            quality={90}
                            objectPosition="50% 50%"
                            alt={carData.name}
                            placeholder="blur"
                            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAQAAAAm93DmAAAAJklEQVR42u3MMQEAAAgDoK234Q2hJwSgmbyqUCgUCoVCoVAoFN4snO4TiSkxjioAAAAASUVORK5CYII="
                        />
                    </Background>
                )}
                <FormComponent
                    carData={carData}
                    setPostNumber={setPostNumber}
                    filteredDealers={filteredDealers}
                    selectedDealerForm={selectedDealerForm}
                    setSelectedDealerForm={setSelectedDealerForm}
                    dealerPreviouslySelected={dealerPreviouslySelected}
                    setDealerPreviouslySelected={setDealerPreviouslySelected}
                />
            </ContentWrapper>
            <FooterModal setShowModal={setShowModal} />
            <Footer />
        </>
    );
};

export default VideoViewingForm;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const Background = styled.div`
    position: absolute;
    min-height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: -1;
`;

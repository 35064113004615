const TestDrive = ({fill, width, height}) => {
    return (
        <svg version="1.1" height={height} width={width} fill={fill} x="0px" y="0px" viewBox="0 0 60 60">
<g>
	<path d="M30,51.415c-13.079,0-21.539-8.46-21.539-21.42c0-13.02,8.399-21.418,21.539-21.418s21.539,8.339,21.539,21.418
		C51.539,43.015,43.08,51.415,30,51.415z M30,49.375c11.819,0,19.439-7.56,19.439-19.38c0-11.879-7.5-19.379-19.439-19.379
		c-11.88,0-19.439,7.561-19.439,19.379C10.561,41.755,18.181,49.375,30,49.375z M31.08,47.156v-6.54c-0.36,0.06-0.72,0.06-1.08,0.06
		s-0.72,0-1.08-0.06v6.54c-9.18-0.42-15.299-6.24-16.02-15.18h6.54c-0.06-0.48-0.12-1.02-0.12-1.5c0-0.18,0-0.359,0-0.54h-6.54
		c0-10.439,6.72-17.099,17.22-17.099s17.22,6.659,17.22,17.099h-6.54c0,0.181,0,0.36,0,0.54c0,0.48-0.061,1.02-0.12,1.5h6.54
		C46.379,40.915,40.26,46.736,31.08,47.156z M40.26,27.896h4.739C44.22,19.737,38.64,14.876,30,14.876s-14.22,4.86-14.999,13.02
		h4.739c1.021-2.88,4.2-4.26,10.26-4.26S39.24,25.016,40.26,27.896z M26.821,40.136c-2.94-1.021-5.46-3.36-6.72-6.12h-4.8
		c1.319,6.18,5.22,9.839,11.52,10.859V40.136z M21.42,30.476c0,4.14,4.2,8.16,8.58,8.16s8.58-4.021,8.58-8.16
		c0-3.54-2.28-4.8-8.58-4.8S21.42,26.936,21.42,30.476z M39.9,34.016c-1.26,2.76-3.779,5.1-6.72,6.12v4.739
		c6.3-1.021,10.2-4.68,11.52-10.859H39.9z"/>
</g>
</svg>

    );
}

export default TestDrive;
import { useEffect, useState } from "react";
import styled from "styled-components";
import Image from "next/image";
import { device } from "../../../../layout/global-styles/device";
import ReactHtmlParser from "react-html-parser";

const CarColorPicker = ({
    carData,
    notOT = false,
    chosenColor = undefined,
    notitle = undefined,
    noColorName,
    fullWidth,
}: CarColorPrickerProps) => {
    const categories = carData.slice(1).map((c) => c.title);
    let firstCategory = categories[0];
    if (chosenColor) {
        for (const c of categories) {
            if (c === chosenColor) {
                firstCategory = c;
            }
        }
    }
    const [categoryState, setCategoryState] = useState(firstCategory);
    const [activeState, setActiveState] = useState(firstCategory.title);

    useEffect(() => {
        setCategoryState(firstCategory);
    }, [firstCategory]);

    return (
        <>
            <CarColorPickerWrapper fullWidth={fullWidth}>
                {!notitle && <Title notOT={notOT}>Se våre farger</Title>}

                <ContentContainer>
                    {!fullWidth && (
                        <CarImgContainer notOT={notOT}>
                            {carData &&
                                carData.slice(1).map((item) => {
                                    return (
                                        categoryState === item.title && (
                                            <>
                                                <Image
                                                    unoptimized={true}
                                                    src={item.carModelImage}
                                                    layout="fill"
                                                    objectFit="contain"
                                                    alt={item.title}
                                                    loading="eager"
                                                    priority={true}
                                                    placeholder="blur"
                                                    blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAQAAAAm93DmAAAAJ0lEQVR42u3MMQEAAAgDINf/t66G0BMCkOl6FaFQKBQKhUKhUCi8WUf9P3Hc1kEjAAAAAElFTkSuQmCC"
                                                />
                                            </>
                                        )
                                    );
                                })}
                        </CarImgContainer>
                    )}
                    {fullWidth && (
                        <CarImgContainer notOT={notOT} fullWidth={fullWidth}>
                            {carData &&
                                carData.slice(1).map((item) => {
                                    return (
                                        categoryState === item.title && (
                                            <>
                                                <ImgDesktop>
                                                    <Image
                                                        unoptimized={true}
                                                        src={item.carModelImage}
                                                        width={1920}
                                                        height={750}
                                                        alt={item.title}
                                                        loading="eager"
                                                        priority={true}
                                                        placeholder="blur"
                                                        blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAQAAAAm93DmAAAAJ0lEQVR42u3MMQEAAAgDINf/t66G0BMCkOl6FaFQKBQKhUKhUCi8WUf9P3Hc1kEjAAAAAElFTkSuQmCC"
                                                    />
                                                </ImgDesktop>
                                                <ImgMobile>
                                                    <Image
                                                        unoptimized={true}
                                                        src={item.carModelImage}
                                                        layout="fill"
                                                        objectFit="cover"
                                                        objectPosition="50% 50%"
                                                        alt={item.title}
                                                        loading="eager"
                                                        priority={true}
                                                        placeholder="blur"
                                                        blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAQAAAAm93DmAAAAJ0lEQVR42u3MMQEAAAgDINf/t66G0BMCkOl6FaFQKBQKhUKhUCi8WUf9P3Hc1kEjAAAAAElFTkSuQmCC"
                                                    />
                                                </ImgMobile>
                                            </>
                                        )
                                    );
                                })}
                        </CarImgContainer>
                    )}
                </ContentContainer>
                {noColorName && (
                    <ContentContainer>
                        <ColorNamesContainer>
                            <ColorName>FARGER</ColorName>
                        </ColorNamesContainer>
                    </ContentContainer>
                )}
                <ContentContainer>
                    <ColorPickerContainer notOT={notOT}>
                        {!noColorName && (
                            <ColorNamesContainer>
                                {carData &&
                                    carData.slice(1).map((item) => {
                                        return (
                                            categoryState === item.title && (
                                                <>
                                                    <ColorName key={item.title}>
                                                        {ReactHtmlParser(
                                                            item.title
                                                        )}
                                                    </ColorName>
                                                </>
                                            )
                                        );
                                    })}
                            </ColorNamesContainer>
                        )}
                        <ColorContainer>
                            {carData &&
                                carData.slice(1).map((item) => {
                                    return (
                                        <>
                                            <Color
                                                notOT={notOT}
                                                onClick={() => {
                                                    setCategoryState(
                                                        item.title
                                                    );
                                                    setActiveState(item.title);
                                                }}
                                                className={
                                                    activeState == item.title
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Image
                                                    unoptimized={true}
                                                    src={item.colorPickerImage}
                                                    width={34}
                                                    height={34}
                                                    objectFit="cover"
                                                    alt={item.title}
                                                    loading="eager"
                                                    priority={true}
                                                    placeholder="blur"
                                                    blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                                                />
                                            </Color>
                                        </>
                                    );
                                })}
                        </ColorContainer>
                    </ColorPickerContainer>
                </ContentContainer>
            </CarColorPickerWrapper>
        </>
    );
};

export default CarColorPicker;

interface CarColorPickerStyledComponent {
    notOT?: boolean;
    fullWidth?: boolean;
}
interface CarColorPrickerProps {
    carData: any;
    notOT?: boolean;
    chosenColor?: string;
    notitle?: boolean;
    noColorName?: boolean;
    fullWidth?: boolean;
}

const ImgDesktop = styled.div`
    display: none;

    @media ${device.tablet} {
        display: block;
    }
`;

const ImgMobile = styled.div`
    display: block;
    position: relative;
    min-height: 35vh;
    min-width: 320px;
    max-width: 411px;
    width: 100vw;

    @media ${device.tablet} {
        display: none;
    }
`;

const CarColorPickerWrapper = styled.div<CarColorPickerStyledComponent>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.light};
    padding: ${(props) => (props.fullWidth ? "0 0 5%" : "5% 2%")};
`;

const Title = styled.h4<CarColorPickerStyledComponent>`
    font-family: "nouvelr-bold";
    width: fit-content;
    margin-left: 10%;
    border-bottom: ${(props) => (props.notOT ? "none" : "1px solid #000000}")};
    padding-bottom: ${(props) => (props.notOT ? "0" : "5px")};
    margin-bottom: ${(props) => (props.notOT ? "0" : "1rem")};
    text-transform: ${(props) => (props.notOT ? "uppercase" : "none")};
    font-size: ${(props) => (props.notOT ? "18px" : "2.125rem")};
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 1;
`;

const CarImgContainer = styled.div<CarColorPickerStyledComponent>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    min-height: ${(props) => (props.fullWidth ? "0px" : "300px")};
    margin: ${(props) => (props.notOT ? "0" : "2rem")};
    margin: ${(props) => (props.fullWidth ? "0 0 2rem" : "unset")};
`;

const ColorPickerContainer = styled.div<CarColorPickerStyledComponent>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: ${(props) => (props.notOT ? "-3rem 0 0 0" : "1rem 0")};
`;

const ColorNamesContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 1rem 0;
`;

const ColorName = styled.p`
    font-family: "nouvelr-bold";
    font-size: 1.1rem;
`;

const ColorContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    //height: 100%;
    flex-wrap: wrap;

    @media ${device.mobileL} {
        flex-wrap: wrap;
    }
`;

const Color = styled.button<CarColorPickerStyledComponent>`
    display: flex;
    overflow: hidden;
    border-radius: 50%;
    //height: 100%;
    margin: ${(props) => (props.notOT ? "0.5rem 0.5rem 0 0" : "1rem 1rem 0 0")};
    border: 1px solid transparent;

    :hover {
        cursor: pointer;
        box-shadow: 0 0 0 1px #00000035;
        /* border: 1px solid ${(props) => props.theme.black}; */
    }
    &.active {
        //border: 1px solid ${(props) => props.theme.black};
        box-shadow: 0 0 0 1px #0091da90;
    }

    @media ${device.mobileL} {
        flex-wrap: wrap;
    }
`;

const OutlinePhone = ({ fill, width, height }) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 60 60"
                height={height}
                width={width}
                fill={fill}
            >
                <g>
                    <path d="M15.93,23.46c0-3.74,2.21-6.14,5.86-7.63L29,23.08,25.1,26.82l9.12,9.12L38,32l7.3,7.3c-1.49,3.69-4.18,5.9-7.68,5.85-4,0-8.16-2.4-13.73-8S15.93,27.45,15.93,23.46ZM37.77,43.19c2.16,0,4-1.34,5.19-3.55l-4.9-4.9-3.89,3.94L22.36,26.82l3.94-3.74-4.9-4.9c-2.2,1.15-3.55,2.93-3.55,5.09,0,3.46,2.26,7.3,7.44,12.48S34.37,43.19,37.77,43.19Z" />
                </g>
            </svg>
        </>
    );
};

export default OutlinePhone;

import React from "react";
import styled, { css } from "styled-components";
import OutlineCar from "../../../icons/OutlineCar";
import OutlinePhone from "../../../icons/OutlinePhone";
import OutlinePin from "../../../icons/OutlinePin";
import { device } from "../../../../layout/global-styles/device";

export interface FormHeaderProps {
    carData?: any;
    progress?: number;
}

const FormHeader = ({ carData, progress }: FormHeaderProps) => {
    return (
        <>
            <PageTitle>
                <span>Du har valgt vår modell</span>
                <span>{carData.name}</span>
            </PageTitle>
            <ProgressContainer>
                <IconsList progress={progress}>
                    <IconsContainer>
                        <OutlineCar
                            width={40}
                            height={40}
                            fill={progress >= 1 ? "#EFDF00" : "#000000"}
                        />
                        <OutlinePhone
                            width={40}
                            height={40}
                            fill={progress >= 2 ? "#EFDF00" : "#000000"}
                        />
                        <OutlinePin
                            width={40}
                            height={40}
                            fill={progress >= 3 ? "#EFDF00" : "#000000"}
                        />
                    </IconsContainer>
                    <IconsContainer>
                        <li></li>
                        <hr />
                        <li></li>
                        <hr />
                        <li></li>
                    </IconsContainer>
                </IconsList>
            </ProgressContainer>
        </>
    );
};

export default FormHeader;

const PageTitle = styled.h2`
    font-size: 1.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const ProgressContainer = styled.div`
    width: 100%;

    @media ${device.tablet} {
        width: 70%;
    }
`;

const IconsList = styled.ul<FormHeaderProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 1rem 0;

    li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        line-height: 120%;

        text-decoration: none;
        list-style: none;

        ::before {
            content: "・";
            font-weight: bolder;
            font-size: 3rem;
            margin: 0 -0.8rem;
        }
    }

    hr {
        text-decoration: none;
        background-color: ${(props) => props.theme.black};
        border: 0;
        width: 100%;
        height: 2px;
    }

    /* Progress selectors are still a bit repetitive */

    ${(props) =>
        props.progress === 1 &&
        css`
            li :nth-of-type(1) {
                color: ${(props) => props.theme.primary};
            }

            hr :nth-of-type(1) {
                background-color: ${(props) => props.theme.primary};
            }
        `}

    ${(props) =>
        props.progress === 2 &&
        css`
            li :nth-of-type(-n + 2) {
                color: ${(props) => props.theme.primary};
            }
            hr :nth-of-type(-n + 2) {
                background-color: ${(props) => props.theme.primary};
            }
        `}

    ${(props) =>
        props.progress === 3 &&
        css`
            li :nth-of-type(-n + 3) {
                color: ${(props) => props.theme.primary};
            }
            hr :nth-of-type(-n + 2) {
                background-color: ${(props) => props.theme.primary};
            }
        `}
`;

const IconsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    svg {
        margin: 0 -8px;
    }
`;

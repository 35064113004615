import { getTime } from "../form-get-time";

export const TestDriveData = ({
    depId,
    dealer,
    name,
    email,
    phone,
    car,
    leadName,
    postNumber,
}) => {
    return {
        ExtID: "1",
        SChemaID: "1",
        SchemaName: "TestDrive",
        CodesetID: "Fortress01",
        RegistrationDate: getTime().toString(),
        DepartmentID: depId,
        DepartmentName: dealer,
        InquiryTypeID: "TestDrive",
        InquiryTypeDescription: "TestDrive",
        Name: name,
        Email: email,
        Mobile: phone,
        HTTPlink: window.location.href,
        Zipcode: postNumber,
        CommentList: [
            {
                CommentDescription: "Forhandler",
                Comment: dealer,
            },
            {
                CommentDescription: "Kommentar",
                Comment: "",
            },
        ],
        Product: [
            {
                ProductType: "newcar",
                Maingroup: "Renault",
                Subset: leadName,
                Description: car,
            },
        ],
        Campaign: {},
    };
};

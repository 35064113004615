import React from "react";

interface ArrowLeftProp {
    fill: string;
    width: number;
    height: number;
}

const ArrowLeft: React.FC<ArrowLeftProp> = ({ fill, width, height }) => {
    return (
        <svg
            version="1.1"
            height={height}
            width={width}
            fill={fill}
            x="0px"
            y="0px"
            viewBox="0 0 60 60"
        >
            <g>
                <path
                    d="M41.518,47.096c-8.34-5.46-14.52-9.899-22.199-16.079v-2.101c7.439-5.999,13.5-10.379,22.199-16.079l1.32,1.86
		    c-7.8,4.979-13.74,9.239-21.119,15.239c7.379,6.06,13.319,10.319,21.119,15.299L41.518,47.096z"
                />
            </g>
        </svg>
    );
};

export default ArrowLeft;

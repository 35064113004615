const CarBuilder = ({fill, width, height}) => {
    return (
        <svg viewBox="0 0 60 60" height={height} width={width} fill={fill}>
            <g>
                <path d="M31.58,21.16H21.12c-2.26,0-3.17,2-4.32,6.14H40.46c-.24-.81-.43-1.53-.67-2.16H45.7v1.68l-2.55,1.11a6.2,6.2,0,0,1,2.45,4.84V43.48a1.56,1.56,0,0,1-1.54,1.58h-4.8a1.64,1.64,0,0,1-1.63-1.58V41.13h-18v2.35A1.64,1.64,0,0,1,18,45.06H13.2a1.56,1.56,0,0,1-1.54-1.58V32.77a6.17,6.17,0,0,1,2.45-4.84l-2.55-1.11V25.14h3.8c1.34-3.6,2.59-5.9,5.47-5.9H31.58Zm9.89,8H15.79a5.39,5.39,0,0,0-2.16,3.6V43.14h4V39.21H39.6v3.93h4V32.77A5.39,5.39,0,0,0,41.47,29.17Zm-25.92,4a2,2,0,0,1,4.08,0,2,2,0,0,1-4.08,0ZM48.82,17c-1.59.24-2,.72-2,1.58a5.71,5.71,0,0,0,.38,1.88l.29.72C46.17,22,44.3,23,42.81,23.7l-.33-.57a2.3,2.3,0,0,0-1.92-1.35,2.21,2.21,0,0,0-1.92,1.35l-.34.57a38.77,38.77,0,0,1-4.61-2.49l.24-.72a5.46,5.46,0,0,0,.39-1.88c0-.86-.43-1.34-2-1.58l-.72-.1V11.41l.72-.09c1.54-.24,2-.68,2-1.54a5.21,5.21,0,0,0-.39-1.87l-.24-.77A45.62,45.62,0,0,1,38.3,4.69l.34.58a2.39,2.39,0,0,0,1.92,1.34,2.5,2.5,0,0,0,1.92-1.34l.33-.58c1.49.67,3.36,1.73,4.66,2.45l-.29.77a5.44,5.44,0,0,0-.38,1.87c0,.86.43,1.3,2,1.54l.67.09v5.52ZM36,20.25c.58.38,1.15.71,1.87,1.1a3.4,3.4,0,0,1,2.74-1.44,3.51,3.51,0,0,1,2.73,1.39c.68-.38,1.25-.67,1.83-1a8.32,8.32,0,0,1-.34-1.72,3.26,3.26,0,0,1,2.79-3.27V13a3.19,3.19,0,0,1-2.79-3.17,8.26,8.26,0,0,1,.34-1.73c-.58-.34-1.15-.62-1.83-1a3.64,3.64,0,0,1-2.73,1.39A3.41,3.41,0,0,1,37.82,7,15.28,15.28,0,0,0,36,8.15a10.56,10.56,0,0,1,.29,1.68A3.08,3.08,0,0,1,33.55,13v2.3a3.18,3.18,0,0,1,2.74,3.27A7.59,7.59,0,0,1,36,20.25Zm1.39-6.05a3.2,3.2,0,1,1,3.22,3.17A3.22,3.22,0,0,1,37.34,14.2Zm.29,19a2,2,0,1,1,2,2A2,2,0,0,1,37.63,33.16Zm4.32-19a1.39,1.39,0,0,0-1.39-1.39,1.33,1.33,0,0,0-1.39,1.39,1.43,1.43,0,0,0,1.39,1.44A1.5,1.5,0,0,0,42,14.15Z"/>
            </g>
        </svg>
    );
}

export default CarBuilder;
import styled from "styled-components";

const Input = styled.input`
    font-family: "nouvelr-regular";
    padding: 0.6rem 1rem;
    width: 100%;
    margin: 0.2rem 0;
    border: 1px solid transparent;
    border-bottom: 1px solid ${(props) => props.theme.medium};

    ::placeholder {
        color: ${(props) => props.theme.medium};
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    [type="number"] {
        -moz-appearance: textfield; /* Firefox */
    }
`;

export default Input;

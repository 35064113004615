export const AdformPush = (window, id, title, payload) => {
    try {
        if (window && window.adf) {
            window.adf.ClickTrack(window, id, title, payload);
        }
    } catch (e) {
        console.info("Adform tracking failed");
        console.error(e);
    }
};

import Link from "next/link";
import styled from "styled-components";

import { GetLocalConfiguratorLink } from "../lib/transformers/configurator";
import { device } from "../layout/global-styles/device";
import CarBuilder from "./icons/CarBuilder";
import TestDrive from "./icons/TestDrive";
import React, { useState } from "react";
import PageOvertake from "./PageOvertake";
import { GetDealers } from "../lib/queries/DealerQuery";
import { TestDriveCarInfo } from "../lib/queries/TestDriveCarInfoQuery";
import ReactHtmlParser from "react-html-parser";

interface OfferComponentProps {
    data?: any;
    carSlug: string;
    carName: string;
    carBuilder?: boolean;
    testDrive?: boolean;
    testDriveData?: TestDriveCarInfo;
}

const OfferComponent = ({
    data,
    carSlug,
    carName,
    carBuilder,
    testDrive,
    testDriveData = undefined,
}: OfferComponentProps) => {
    const [showModal, setShowModal] = useState(false);
    const dealers = GetDealers();
    const heading = data?.heading;
    const text = data?.text;

    return (
        <ColorWrapper>
            {carBuilder && (
                <Link href={GetLocalConfiguratorLink(carSlug)} passHref>
                    <OfferComponentWrapper>
                        <ContentContainer>
                            <OfferHeading>
                                {heading ??
                                    "Bygg din bil og få et skreddersydd tilbud!"}
                            </OfferHeading>
                            <OfferText>
                                {(text && ReactHtmlParser(text)) ??
                                    `Benytt mulighetene og bygg nye${" "}
                                ${
                                    carName ?? "bil"
                                } etter dine ønsker og behov!`}
                            </OfferText>
                        </ContentContainer>
                        <CTAwrapper>
                            <CarBuilder
                                fill={"#EFDF00"}
                                width={60}
                                height={60}
                            />
                            <CtaText>bygg din bil</CtaText>
                        </CTAwrapper>
                    </OfferComponentWrapper>
                </Link>
            )}

            {testDrive && (
                <OfferComponentWrapper onClick={() => setShowModal(true)}>
                    {carSlug !== "nye-kangoo" && (
                        <ContentContainer>
                            <OfferHeading>
                                {heading ??
                                    "Book prøvekjøring og prøv ut bilens funksjonaliteter!"}
                            </OfferHeading>
                            <OfferText>
                                {(text && ReactHtmlParser(text)) ??
                                    `Benytt muligheten til en uforpliktende
                                prøvekjøring av ${carName ?? "bilen"} og finn ut
                                om den passer deg!`}
                            </OfferText>
                        </ContentContainer>
                    )}
                    {carSlug === "nye-kangoo" && (
                        <ContentContainer>
                            <OfferHeading>Prøvekjør kangoo idag</OfferHeading>
                            <OfferText>
                                Opplev en enklere hverdag med nye Kangoo varebil
                            </OfferText>
                        </ContentContainer>
                    )}
                    <CTAwrapper>
                        <TestDrive fill={"#EFDF00"} width={60} height={60} />
                        <CtaText>book prøvekjøring</CtaText>
                    </CTAwrapper>
                </OfferComponentWrapper>
            )}
            <PageOvertake
                data={dealers}
                carData={testDriveData}
                showModal={showModal}
                setShowModal={setShowModal}
                componentType={"testdrive"}
            />
        </ColorWrapper>
    );
};

export default OfferComponent;

const CTAwrapper = styled.a`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 auto 0;

    text-decoration: none;
    color: ${(props) => props.theme.white};

    @media ${device.laptop} {
        margin: -0.8rem 0 0;
        min-width: 15%;
    }
`;

const ColorWrapper = styled.section`
    background-color: ${(props) => props.theme.black};
    width: 100%;
`;
const OfferComponentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 0rem;
    max-width: 1200px;
    margin: auto;
    align-items: center;
    cursor: pointer;

    @media ${device.laptop} {
        flex-flow: row nowrap;
        padding: 2rem;
    }
`;
const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 0.7rem;

    @media ${device.tablet} {
        padding: 1rem 2rem 1rem 1rem;
    }

    @media ${device.laptop} {
        align-items: flex-start;
        justify-content: center;
        padding: 1rem 5rem 1rem 1rem;
    }
`;

const OfferHeading = styled.h3`
    font-family: "nouvelr-bold";
    line-height: 1;
    text-transform: uppercase;
    text-align: center;
    color: ${(props) => props.theme.white};

    @media ${device.laptop} {
        text-align: left;
    }
`;
const OfferText = styled.p`
    color: ${(props) => props.theme.white};
    margin-top: 1rem;
    text-align: center;

    @media ${device.laptop} {
        text-align: left;
    }
`;

const CtaText = styled.p`
    color: ${(props) => props.theme.white};
    font-family: "nouvelr-bold";
`;

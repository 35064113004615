const OutlinePin = ({ fill, width, height }) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 60 60"
                height={height}
                width={width}
                fill={fill}
            >
                <g>
                    <path d="M41.79,26c0,6-3.46,12.24-10.13,19.1H29.79C23.16,38.25,19.71,32,19.71,26c0-6.77,4.37-11,11-11S41.79,19.24,41.79,26Zm-11,17.47C37,36.28,39.63,31.05,39.63,26s-3.36-9-8.88-9-8.88,3.84-8.88,9S24.46,36.28,30.75,43.48ZM30.7,21.11a4.81,4.81,0,0,1,5,5,4.8,4.8,0,0,1-5,5,4.77,4.77,0,0,1-4.94-5A4.78,4.78,0,0,1,30.7,21.11Zm0,1.92a2.9,2.9,0,0,0-2.93,3.12,2.86,2.86,0,0,0,2.93,3.07,2.91,2.91,0,0,0,3-3.07A3,3,0,0,0,30.7,23Z" />
                </g>
            </svg>
        </>
    );
};

export default OutlinePin;

import React from "react";
import styled from "styled-components";
import { device } from "../layout/global-styles/device";
import BackArrowButton from "./BackArrowButton";

const Box = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: row nowrap;
    width: 100%;
    padding: 2rem;
    background-color: ${(props) => props.theme.base};
    min-height: 100px;
    max-height: 200px;

    @media ${device.mobileL} {
        justify-content: space-around;
        padding: 4rem;
    }
`;

const Back = styled.a`
    position: absolute;
    left: 10%;
    text-transform: lowercase;
    color: ${(props) => props.theme.white};
    text-decoration: none;

    font-size: 1.5em;

    cursor: pointer;

    @media ${device.mobileL} {
        font-size: 1.4em;
    }

    @media ${device.laptop} {
        font-size: 1.625em;
    }
`;

const Text = styled.h3`
    display: inline;
    position: absolute;
    text-transform: uppercase;
    text-align: center;
    color: ${(props) => props.theme.white};
    font-size: 1.5em;

    @media ${device.mobileL} {
        font-size: 2em;
    }

    @media ${device.laptop} {
        font-size: 2.5em;
    }
`;

const FooterModal = ({ setShowModal }) => {
    return (
        <Box>
            <Back onClick={() => setShowModal(false)}>
                <BackArrowButton bold={false} />
            </Back>
            <Text>Renault</Text>
        </Box>
    );
};

export default FooterModal;

import React, { ReactNode, useEffect } from "react";
import styled from "styled-components";
import OvertakeWrapper from "./OvertakeWrapper";

export type componentTypes =
    | "product"
    | "testdrive"
    | "videoviewing"
    | "service"
    | "forsikring-og-finansiering"
    | "other";
interface PageOvertakeProps {
    title?: string;
    topImage?: string;
    componentType?: componentTypes;
    showModal: any;
    setShowModal: any;
    data?: any;
    carData?: any;
    designData?: any;
    sliderData?: any;
    securityData?: any;
    testDriveData?: any;
    showBuildCar?: any;
    accessoriesData?: any;
    children?: ReactNode;
}

const PageOvertake = ({
    title,
    topImage,
    showModal,
    setShowModal,
    data,
    carData = undefined,
    designData = undefined,
    sliderData = undefined,
    securityData = undefined,
    testDriveData = undefined,
    showBuildCar = undefined,
    accessoriesData = undefined,
    componentType,
    children = null,
}: PageOvertakeProps) => {
    useEffect(() => {
        /* Lock body scroll when modal is open */
        if (showModal) document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "unset";
        };
    }, [showModal]);

    return (
        <PageOvertakeWrapper>
            <OvertakeWrapper
                title={title}
                topImage={topImage}
                showModal={showModal}
                setShowModal={setShowModal}
                data={data}
                designData={designData}
                sliderData={sliderData}
                securityData={securityData}
                carData={carData}
                testDriveData={testDriveData}
                accessoriesData={accessoriesData}
                showBuildCar={showBuildCar}
                componentType={componentType}
            >
                {children}
            </OvertakeWrapper>
        </PageOvertakeWrapper>
    );
};

export default PageOvertake;

const PageOvertakeWrapper = styled.div``;

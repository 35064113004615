import { Formik, Form, useField, useFormikContext } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import styled, { css } from "styled-components";
import * as Yup from "yup";
import axios from "axios";

import { IDealerListData } from "../../../../lib/queries/DealerQuery";
import DealerCard from "./DealerCard";
import VideoPlatform from "./VideoPlatform";
import FormHeader from "./FormProgress";
import { device } from "../../../../layout/global-styles/device";
import DealerContext from "../../../../context/DealerContext";
import { GetCampaignInfo } from "../../../../lib/form-data/campaign-info";
import { VideoViewingData } from "../../../../lib/form-data/video-viewing";
import { AdformPush } from "../../../../lib/tracking/AdformPush";
import Label from "../Label";
import StyledErrorMessage from "../StyledErrorMessage";
import Input from "../Input";
import PostNumberInput from "../PostNumberInput";
import BackupLead from "../../../../lib/form-data/BackupLead";

interface IFormStyleProps {
    formPlacement?: string;
}

interface IFormComponentProps {
    carData: any;
    setPostNumber: any;
    filteredDealers: IDealerListData;
    selectedDealerForm: IDealerListData;
    setSelectedDealerForm: any;
    dealerPreviouslySelected: boolean;
    setDealerPreviouslySelected: any;
}

const MyTextInput = ({ label, ...props }: any) => {
    const [field, meta] = useField(props);
    return (
        <>
            <Label htmlFor={props.id || props.name}>{label}</Label>
            <Input {...field} {...props} />
            {meta.touched && meta.error ? (
                <StyledErrorMessage>{meta.error}</StyledErrorMessage>
            ) : null}
        </>
    );
};

const SubmitErrorMessage = styled.p`
    color: #cc0000;
`;

const FormComponent = ({
    carData,
    setPostNumber,
    filteredDealers,
    selectedDealerForm,
    setSelectedDealerForm,
    dealerPreviouslySelected,
    setDealerPreviouslySelected,
}: IFormComponentProps) => {
    const [progress, setProgress] = useState(1);
    const [isSelected, setIsSelected] = useState(false);
    const [isSelectedPlatform, setIsSelectedPlatform] = useState(false);
    const [selectedPlatform, setSelectedPlatform] = useState("");

    const { selectedDealer } = useContext(DealerContext);
    const [hidePreSelection, setHidePreSelection] = useState(false);

    const formPlacement = carData.formPlacement ?? "right";

    /* 
    Both forms have their own logic inside them, 
    so to avoid refactoring too much we are only fetchinf selectedDealer from the context, 
    which is the one that is setted inside "Finn forhandler"
    */
    const [submitError, setSubmitError] = useState(null);
    const router = useRouter();

    const onHandleSubmit = (values) => {
        const campaign = GetCampaignInfo();

        const payload = [VideoViewingData(values)];
        if (campaign) {
            payload[0].Campaign = campaign;
        }
        if (!payload[0].Campaign) {
            delete payload[0].Campaign;
        }

        if (submitError) {
            setSubmitError(null);
        }
        BackupLead(payload);

        const link =
            window.location.hostname != "renault.no"
                ? "https://acceptlead-test.motorforum.no/api/lead"
                : "https://acceptlead.motorforum.no/api/lead";
        axios
            .post(link, payload, {
                headers: {
                    "X-Token":
                        "8395936f492cac4c7e0db781e894ff21290c8a6c04e914cda0458c634134f857041bba82f9342d575c63e7c8c837e4b5",
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    const { slug } = router.query;
                    router.push(
                        `/avtal-en-videovisning/${slug}/takk?slug=${slug}`
                    );
                    AdformPush(
                        window,
                        41105,
                        "Contact Dealer | Video Appointment form completed on " +
                            values.car,
                        {}
                    );
                }
            })
            .catch((error) => {
                console.error(error);
                setSubmitError(
                    new Error("Feil ved innsending, vennligst prøv igjen!")
                );
            });
    };

    const HandleFieldValues = () => {
        const formik = useFormikContext();

        useEffect(() => {
            /* Update "dealer" form value with 
            selected dealer from DealerCard */
            if (isSelected) {
                formik.setFieldValue("dealer", selectedDealerForm.dealerName);
            }

            return () => {
                setIsSelected(false);
            };
        });

        useEffect(() => {
            /* Clear "dealer" form value and checked status 
            when another "search" happens */
            if (dealerPreviouslySelected && !isSelected) {
                formik.setFieldValue("dealer", "");
                setHidePreSelection(true);
            }

            return () => {
                setDealerPreviouslySelected(false);
            };
        });

        useEffect(() => {
            /* Calls postNumber filter function */
            const postNumberValue = formik.getFieldProps("postNumber").value;
            if (postNumberValue.length === 4 && postNumberValue !== "0000") {
                setPostNumber(postNumberValue);
            }
        }, [formik]);

        useEffect(() => {
            /* Update "videoPlatform" form value with 
            selected videoPlatform from VideoPlatform Card */
            if (isSelectedPlatform) {
                formik.setFieldValue("videoPlatform", selectedPlatform);
            }

            return () => {
                setIsSelectedPlatform(false);
            };
        });

        useEffect(() => {
            /* Updates Progress component based on fields validation */
            const nameField = formik.getFieldMeta("name");
            const emailField = formik.getFieldMeta("email");
            const phoneField = formik.getFieldMeta("phone");
            const dealerField = formik.getFieldMeta("dealer");
            const videoPlatformField = formik.getFieldMeta("videoPlatform");

            if (
                nameField.touched &&
                !nameField.error &&
                !emailField.error &&
                !phoneField.error &&
                !dealerField.error &&
                !videoPlatformField.error
            ) {
                setProgress(3);
            } else if (
                nameField.touched &&
                !nameField.error &&
                !emailField.error &&
                !phoneField.error &&
                !videoPlatformField.error
            ) {
                setProgress(2);
            } else {
                setProgress(1);
            }
        }, [formik]);

        return null;
    };

    return (
        <Formik
            enableReinitialize
            initialValues={{
                name: "",
                email: "",
                phone: "",
                videoPlatform: "",
                postNumber: "",
                car: "",
                dealer: selectedDealer ? selectedDealer.dealerName : "",
                depId: selectedDealer ? selectedDealer.depId : "",
                date: "",
            }}
            validationSchema={Yup.object({
                name: Yup.string()
                    .min(3, "Må bestå av minst 3 tegn.")
                    .required("Vennligst oppgi navnet ditt"),
                email: Yup.string()
                    .email("Vennligst oppgi en korrekt e-post adresse.")
                    .required("Vennligst oppgi en korrekt e-post adresse."),
                phone: Yup.string()
                    .min(8, "Må bestå av minst 8 tegn.")
                    .required("Vennligst oppgi et gyldig telefonnummer."),
                postNumber: Yup.string()
                    .min(4, "Korrekt postnummer må bestå av 4 tegn.")
                    .max(4, "Korrekt postnummer må bestå av 4 tegn.")
                    .required("Vennligst oppgi et gyldig postnummer."),
                car: Yup.string(),
                videoPlatform: Yup.string().required(),
                dealer: Yup.string().required(),
                depId: Yup.string().required(),
                date: Yup.date(),
            })}
            onSubmit={onHandleSubmit}
        >
            {({
                values,
                isSubmitting,
                handleSubmit,
                isValid,
                dirty,
                setFieldValue,
            }) => (
                <>
                    <FormWrapper formPlacement={formPlacement}>
                        <HandleFieldValues />

                        <StyledForm onSubmit={handleSubmit}>
                            <FormHeader carData={carData} progress={progress} />
                            <InputGroup>
                                <MyTextInput
                                    label="Navn: *"
                                    as={Input}
                                    name="name"
                                    placeholder="Kari Nordmann"
                                    type="input"
                                />
                            </InputGroup>
                            <InputGroup>
                                <MyTextInput
                                    label="E-postadresse: *"
                                    as={Input}
                                    name="email"
                                    placeholder="karinordmann@gmail.com"
                                    type="input"
                                />
                            </InputGroup>
                            <InputGroup>
                                <MyTextInput
                                    label="Telefon: *"
                                    as={Input}
                                    name="phone"
                                    placeholder="900 90 900"
                                    type="phone"
                                    min="0"
                                />
                            </InputGroup>
                            <InputGroup>
                                <Label>Platform:</Label>
                                <VideoPlatform
                                    setProgress={setProgress}
                                    setIsSelectedPlatform={
                                        setIsSelectedPlatform
                                    }
                                    selectedPlatform={selectedPlatform}
                                    setSelectedPlatform={setSelectedPlatform}
                                />
                            </InputGroup>

                            <InputGroup>
                                <Label>
                                    Skriv inn ditt postnummer, så finner vi
                                    forhandleren som er nærmest deg.
                                </Label>

                                <PostNumberInput
                                    label="Postnummer:"
                                    name="postNumber"
                                    as={Input}
                                    placeholder=""
                                    value={values.postNumber}
                                    type="text"
                                    inputmode="numeric"
                                    min="0"
                                    pattern="\S+[0-9]*"
                                />
                            </InputGroup>
                            <InputGroup>
                                <Label>Valgt forhandler:</Label>
                            </InputGroup>
                            <DealersContainer>
                                {values.postNumber.length >= 4 &&
                                    filteredDealers &&
                                    filteredDealers.map((dealer) => {
                                        if (
                                            dealer.services.includes(
                                                "forhandler"
                                            )
                                        ) {
                                            return (
                                                <DealerCard
                                                    key={dealer.depId}
                                                    dealer={dealer}
                                                    setSelectedDealer={(
                                                        dealer
                                                    ) => {
                                                        setSelectedDealerForm(
                                                            dealer
                                                        );
                                                        setFieldValue(
                                                            "dealer",
                                                            dealer.dealerName
                                                        );
                                                        setFieldValue(
                                                            "depId",
                                                            dealer.depId
                                                        );
                                                    }}
                                                    setProgress={setProgress}
                                                    setIsSelected={
                                                        setIsSelected
                                                    }
                                                    radioChecked={
                                                        selectedDealerForm
                                                            ? selectedDealerForm.depId ===
                                                              dealer.depId
                                                            : false
                                                    }
                                                    setRadioChecked={() => {}}
                                                />
                                            );
                                        }
                                    })}
                                {selectedDealer && !hidePreSelection && (
                                    <>
                                        {selectedDealer.services.includes(
                                            "forhandler"
                                        ) && (
                                            <DealerCard
                                                dealer={selectedDealer}
                                                setSelectedDealer={(dealer) => {
                                                    setFieldValue(
                                                        "dealer",
                                                        dealer.dealerName
                                                    );
                                                    setFieldValue(
                                                        "depId",
                                                        dealer.depId
                                                    );
                                                    setSelectedDealerForm(
                                                        dealer
                                                    );
                                                }}
                                                setProgress={setProgress}
                                                setIsSelected={setIsSelected}
                                                radioChecked={
                                                    selectedDealer
                                                        ? true
                                                        : false
                                                }
                                                setRadioChecked={() => {}}
                                            />
                                        )}
                                    </>
                                )}
                            </DealersContainer>

                            <input
                                type="hidden"
                                name="car"
                                value={values.car}
                            />
                            <input
                                type="hidden"
                                name="videoPlatform"
                                value={values.videoPlatform}
                            />
                            <input
                                type="hidden"
                                name="dealer"
                                value={values.dealer}
                            />
                            <input
                                type="hidden"
                                name="depId"
                                value={values.depId}
                            />
                            <input
                                type="hidden"
                                name="date"
                                value={values.date}
                            />

                            {submitError && (
                                <SubmitErrorMessage>
                                    {submitError.message}
                                </SubmitErrorMessage>
                            )}
                            <Submit
                                type="submit"
                                disabled={!(isValid && dirty) || isSubmitting}
                                onClick={() => {
                                    setFieldValue("car", carData.name);
                                    setFieldValue(
                                        "videoPlatform",
                                        selectedPlatform
                                    );
                                    setFieldValue("date", new Date());
                                }}
                            >
                                avtal en videovisning
                            </Submit>
                        </StyledForm>
                    </FormWrapper>
                </>
            )}
        </Formik>
    );
};

export default FormComponent;

const FormWrapper = styled.div<IFormStyleProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${(props) =>
        props.formPlacement.includes("left") &&
        css`
            margin-right: auto;
        `}
    ${(props) =>
        props.formPlacement.includes("center") &&
        css`
            margin: 0 auto;
        `}
    ${(props) =>
        props.formPlacement.includes("right") &&
        css`
            margin-left: auto;
        `}
`;

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;

    margin: 1rem 2rem;
    padding: 1rem;
    background-color: ${(props) => props.theme.white};

    @media ${device.tablet} {
        width: 70%;
    }

    @media ${device.laptop} {
        width: 60%;
        margin: 1rem 4rem;
        padding: 1rem 2.5rem;
    }
`;

const InputGroup = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 0.4rem 0;
`;

const DealersContainer = styled(InputGroup)`
    min-height: 1rem;
`;

const Submit = styled.button`
    width: 100%;
    margin: 2.5rem 0;
    cursor: pointer;
    padding: 1rem;
    border: 1px solid transparent;
    font-size: 1.15rem;

    background-color: ${(props) => props.theme.primary};

    &:disabled {
        background-color: ${(props) => props.theme.medium};
    }
`;

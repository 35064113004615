import React from "react";
import styled from "styled-components";
import Image from "next/image";

import TopBanner from "../TopBanner";
import DoubleParagraph from "../../DoubleParagraph";
import FooterHotlinks from "../../FooterHotlinks";
import FooterModal from "../../FooterModal";

const InsuranceOvertake = ({ showModal, setShowModal, data }) => {
    const { title, topImage, extraTextLongForm } = data;

    return (
        <>
            <TopBanner
                title={title}
                showModal={showModal}
                setShowModal={setShowModal}
            />

            <ContentWrapper>
                <ImageWrapper>
                    <TopImage
                        unoptimized={true}
                        src={topImage}
                        width={1920}
                        height={700}
                        objectFit="cover"
                        loading="eager"
                        objectPosition="50% 50%"
                        placeholder="blur"
                        blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAQAAAAm93DmAAAAJklEQVR42u3MMQEAAAgDoC251Q2hJwSgmbyqUCgUCoVCoVAoFN4spocUAbrGeLoAAAAASUVORK5CYII="
                    />
                </ImageWrapper>

                <DoubleParagraph data={extraTextLongForm} />
            </ContentWrapper>
            <FooterHotlinks />
            <FooterModal setShowModal={setShowModal} />
        </>
    );
};

export default InsuranceOvertake;

const ContentWrapper = styled.div`
    /* Detach it from the top banner due to positioning styles */
    display: flex;
    flex-direction: column;
`;

const TopImage = styled(Image)``;

const ImageWrapper = styled.div`
    display: flex;
`;

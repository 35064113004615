import React from "react";
import styled from "styled-components";
import { device } from "../../layout/global-styles/device";

interface TopBannerStyled {
    transparent?: boolean;
    historyBack?: boolean;
}

const TopBanner = ({
    title,
    showModal,
    setShowModal,
    transparent = false,
    historyBack = false,
}) => {
    return (
        <>
            <TopBannerWrapper transparent={transparent}>
                <Container>
                    <PageTitle> {title} </PageTitle>
                    <CloseIcon
                        onClick={() =>
                            historyBack
                                ? history.back()
                                : setShowModal(!showModal)
                        }
                    >
                        <div />
                        <div />
                    </CloseIcon>
                </Container>
            </TopBannerWrapper>
        </>
    );
};

export default TopBanner;

const TopBannerWrapper = styled.div<TopBannerStyled>`
    background-color: ${(props) =>
        props.transparent ? "transparent" : "#000000"};
    top: 0;
    left: 0;
    width: 100%;
    min-height: 5rem;
    padding: 2% 5%;

    position: ${(props) => (props.transparent ? "absolute" : "sticky")};
    display: flex;
    align-items: center;
    justify-content: flex-start;

    overflow: hidden;

    z-index: 100;

    @media ${device.mobileL} {
        min-height: 5rem;
    }
`;

const Container = styled.div`
    width: 100%;
    max-width: 1440px;
`;

const PageTitle = styled.h2`
    color: ${(props) => props.theme.white};
    vertical-align: middle;
    text-transform: uppercase;
    font-family: "nouvelr-bold";
    font-size: 2em;
    max-width: 90%;

    @media ${device.mobileL} {
        font-size: 2.5em;
    }
`;

const CloseIcon = styled.button`
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    background: transparent;
    border: none;
    cursor: pointer;

    top: 50%;
    right: 5%;
    position: absolute;

    z-index: 100;

    width: 24px;
    height: 24px;

    div {
        width: 28px;
        height: 3px;
        background: ${(props) => props.theme.white};
        border-radius: 5px;
        transform-origin: 5px;
        position: relative;
        transition: 200ms ease;

        &:nth-child(1) {
            transform: rotate(45deg);
        }

        &:nth-child(2) {
            transform: rotate(-45deg);
        }
    }

    @media (min-width: 900px) {
        width: 24px;
        height: 24px;

        div {
            width: 28px;
            height: 3px;
        }
    }
`;

import Link from "next/link";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { device } from "../layout/global-styles/device";
import ModelSection from "../lib/modelsection";
import { IBrochuresData } from "../lib/queries/BrochuresProductPageQuery";
import { GetDealers } from "../lib/queries/DealerQuery";
import { TestDriveCarInfo } from "../lib/queries/TestDriveCarInfoQuery";
import { GetLocalConfiguratorLink } from "../lib/transformers/configurator";
import CarBuilder from "./icons/CarBuilder";
import MapPin from "./icons/MapPin";
import PdfIcon from "./icons/PdfIcon";
import TestDrive from "./icons/TestDrive";
import PageOvertake from "./PageOvertake";

const Wrapper = styled.section`
    background-color: ${(props) => props.theme.black};
    width: 100%;
`;
const Flexbox = styled.div<FooterHotlinksStyledComponent>`
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    min-height: fit-content;
    padding: ${(props) =>
        props.isTopModel ? "0rem 2.5rem 2rem 2.5rem" : "2rem 5rem 2rem 5rem"};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${(props) => (props.hasTopMargin ? "2rem" : "0")};
    flex-direction: ${(props) => (props.isTopModel ? "row" : "column")};
    flex-wrap: ${(props) => (props.isTopModel ? "wrap" : "nowrap")};

    @media ${device.tablet} {
        flex-wrap: nowrap;
        flex-direction: row;
        padding: ${(props) =>
            props.isTopModel
                ? "0rem 10rem 2rem 10rem"
                : "2rem 10rem 2rem 10rem"};
    }
`;
const FlexItem = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin: 5% 5%;
    color: ${(props) => props.theme.white};
    text-decoration: none;
    text-align: center;
    cursor: pointer;

    @media ${device.tablet} {
        margin: 2% 5%;
    }
`;
const Text = styled.p`
    color: ${(props) => props.theme.white};
    text-align: center;
    font-family: "nouvelr-bold";
`;

interface FooterHotlinksProps {
    isTopModel?: boolean;
    hasTopMargin?: boolean;
    testDriveData?: TestDriveCarInfo;
    hasBrochures?: boolean;
    carBrochures?: IBrochuresData;
    showBuildCar?: boolean;
}
interface FooterHotlinksStyledComponent {
    isTopModel?: boolean;
    hasTopMargin?: boolean;
}

const FooterHotlinks = ({
    isTopModel,
    hasTopMargin,
    testDriveData = undefined,
    hasBrochures = false,
    carBrochures = undefined,
    showBuildCar = true,
}: FooterHotlinksProps) => {
    const [showModal, setShowModal] = useState(
        process.browser ? ModelSection.isTestDrive() : false
    );
    const dealers = GetDealers();

    useEffect(() => {
        if (showModal === false && ModelSection.isTestDrive()) {
            history.back();
        }
    }, [showModal]);

    const hashchange = () => {
        setShowModal(ModelSection.isTestDrive());
    };

    useEffect(() => {
        window.addEventListener("hashchange", hashchange);
        return () => {
            window.removeEventListener("hashchange", hashchange);
        };
    }, []);

    const cLink = GetLocalConfiguratorLink(testDriveData?.slug);

    return (
        <Wrapper>
            <Flexbox isTopModel={isTopModel} hasTopMargin={hasTopMargin}>
                {showBuildCar && (
                    <Link href={cLink} passHref>
                        <FlexItem>
                            <CarBuilder fill="#EFDF00" width={50} height={50} />
                            <Text>bygg din bil</Text>
                        </FlexItem>
                    </Link>
                )}
                {testDriveData && testDriveData.testDriveImage && (
                    <FlexItem
                        onClick={() => {
                            ModelSection.setLocationHash(
                                "pr%C3%B8vekj%C3%B8ring"
                            );
                            setShowModal(true);
                        }}
                    >
                        <TestDrive fill="#EFDF00" width={50} height={50} />
                        <Text>book prøvekjøring</Text>
                    </FlexItem>
                )}
                {!testDriveData && (
                    <Link href="/prøvekjør" passHref>
                        <FlexItem>
                            <TestDrive fill="#EFDF00" width={50} height={50} />
                            <Text>book prøvekjøring</Text>
                        </FlexItem>
                    </Link>
                )}
                <Link href="/finn-forhandlere" passHref>
                    <FlexItem>
                        <MapPin fill="#EFDF00" width={50} height={50} />
                        <Text>finn din forhandler</Text>
                    </FlexItem>
                </Link>
                <PageOvertake
                    data={dealers}
                    carData={testDriveData}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    componentType={"testdrive"}
                />

                {hasBrochures &&
                    carBrochures &&
                    carBrochures.map((pdf) => {
                        return (
                            pdf.priceList && (
                                <Link
                                    key={pdf.slug}
                                    href={pdf.priceList.href}
                                    passHref
                                >
                                    <FlexItem target="_blank" rel="noreferrer">
                                        <PdfIcon
                                            fill="#EFDF00"
                                            width={50}
                                            height={50}
                                        />
                                        <Text>{pdf.priceList.text}</Text>
                                    </FlexItem>
                                </Link>
                            )
                        );
                    })}
                {hasBrochures && carBrochures && carBrochures[0].brochure && (
                    <Link href={carBrochures[0].brochure.href} passHref>
                        <FlexItem target="_blank" rel="noreferrer">
                            <PdfIcon fill="#EFDF00" width={50} height={50} />
                            <Text>{carBrochures[0].brochure.text}</Text>
                        </FlexItem>
                    </Link>
                )}
                {hasBrochures && carBrochures && carBrochures[0].accessories && (
                    <Link href={carBrochures[0].accessories.href} passHref>
                        <FlexItem target="_blank" rel="noreferrer">
                            <PdfIcon fill="#EFDF00" width={50} height={50} />
                            <Text>{carBrochures[0].accessories.text}</Text>
                        </FlexItem>
                    </Link>
                )}
            </Flexbox>
        </Wrapper>
    );
};
export default FooterHotlinks;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Image from "next/image";
import ReactHtmlParser from "react-html-parser";

import TopBanner from "../TopBanner";
import FormComponent from "../Forms/OrderService/FormComponent";
import { formHelpers } from "../../../lib/formHelpers";
import FooterModal from "../../FooterModal";
import Footer from "../../Footer/Footer";

const ServiceOvertake = ({ showModal, setShowModal, data }) => {
    const { title, topImage, content } = data;

    const [postNumber, setPostNumber] = useState<string>();
    const fullDealersList = data.dealersList;
    const [filteredDealers, setFilteredDealers] = useState() as any[];
    const [selectedDealer, setSelectedDealer] = useState(fullDealersList);
    const [dealerPreviouslySelected, setDealerPreviouslySelected] =
        useState(false);

    useEffect(() => {
        if (postNumber) {
            const filteredDealers = formHelpers.getPostalNumber(
                postNumber,
                fullDealersList
            );
            setFilteredDealers(filteredDealers);
            setDealerPreviouslySelected(true);
        }
    }, [postNumber, fullDealersList]);

    return (
        <>
            <TopBanner
                title={title}
                showModal={showModal}
                setShowModal={setShowModal}
            />

            <ContentWrapper>
                <ImageWrapper>
                    <TopImage
                        unoptimized={true}
                        src={topImage}
                        width={1920}
                        height={700}
                        objectFit="cover"
                        loading="eager"
                        objectPosition="50% 50%"
                        placeholder="blur"
                        blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAQAAAAm93DmAAAAJklEQVR42u3MMQEAAAgDoC251Q2hJwSgmbyqUCgUCoVCoVAoFN4spocUAbrGeLoAAAAASUVORK5CYII="
                    />
                </ImageWrapper>
                <DescriptionWrapper>
                    {ReactHtmlParser(content)}
                </DescriptionWrapper>
                <FormComponent
                    selectedService={data.selectedService}
                    services={data.services}
                    availableCars={data.availableCars}
                    postNumber={postNumber}
                    setPostNumber={setPostNumber}
                    filteredDealers={filteredDealers}
                    selectedDealer={selectedDealer}
                    setSelectedDealer={setSelectedDealer}
                    dealerPreviouslySelected={dealerPreviouslySelected}
                    setDealerPreviouslySelected={setDealerPreviouslySelected}
                />
            </ContentWrapper>
            <FooterModal setShowModal={setShowModal} />
            <Footer />
        </>
    );
};

export default ServiceOvertake;

export const ContentWrapper = styled.div`
    /* Detach it from the top banner due to positioning styles */
    display: flex;
    flex-direction: column;
`;

export const TopImage = styled(Image)``;

export const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const DescriptionWrapper = styled.div`
    background: black;
    color: white;
    padding: 2rem;
    text-align: center;

    p {
        font-size: 1rem;
    }
`;

interface ConfiguratorInfo {
    title: string;
    description: string;
    link: string;
}

export const GetLocalConfiguratorLink = (slug?: string): string => {
    return slug ? "/konfigurator/" + slug : "/konfigurator";
};

export const GetConfiguratorInfo = (slug?: string): ConfiguratorInfo => {
    switch (slug) {
        case "clio-hybrid":
            return {
                title: "Bygg din nye CLIO E-Tech hybrid",
                description:
                    "Konfigurer nye CLIO E-Tech hybrid etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/clio-5-hybrid/be-om-tilbud/",
            };
        case "megane-sport-tourer-plugin-hybrid":
            return {
                title: "Bygg din nye MEGANE plug-in hybrid",
                description:
                    "Konfigurer nye MEGANE plug-in hybrid etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/megane-iv-sport-tourer-phev/be-om-tilbud/",
            };
        case "nye-captur":
            return {
                title: "Bygg din nye nye CAPTUR",
                description:
                    "Konfigurer nye CAPTUR etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/nye-captur/be-om-tilbud/",
            };
        case "nye-captur-plug-in-hybrid":
            return {
                title: "Bygg din nye CAPTUR plug-in hybrid",
                description:
                    "Konfigurer nye CAPTUR plug-in hybrid etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/nye-captur-phev/be-om-tilbud/",
            };
        case "nye-zoe":
            return {
                title: "Bygg din nye ZOE",
                description:
                    "Konfigurer nye ZOE etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/nye-zoe/be-om-tilbud/",
            };
        case "clio":
            return {
                title: "Bygg din CLIO",
                description:
                    "Konfigurer CLIO etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/clio-4/be-om-tilbud/",
            };
        case "megane":
            return {
                title: "Bygg din MEGANE",
                description:
                    "Konfigurer MEGANE etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/megane-iv-kombi/be-om-tilbud/",
            };
        case "megane-sport-tourer":
            return {
                title: "Bygg din MEGANE ST",
                description:
                    "Konfigurer MEGANE ST etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/megane-iv-sport-tourer/be-om-tilbud/",
            };
        case "captur":
            return {
                title: "Bygg din CAPTUR",
                description:
                    "Konfigurer CAPTUR etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/captur/be-om-tilbud/",
            };
        case "nye-kadjar":
            return {
                title: "Bygg din KADJAR",
                description:
                    "Konfigurer KADJAR etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/kadjar/be-om-tilbud/",
            };
        case "twizy":
            return {
                title: "Bygg din TWIZY",
                description:
                    "Konfigurer TWIZY etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/twizy/be-om-tilbud/",
            };
        case "kangoo-electric":
            return {
                title: "Bygg din Kangoo E-Tech electric",
                description:
                    "Konfigurer Kangoo E-Tech electric etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/kangoo-ze/be-om-tilbud/",
            };
        case "kangoo-maxi-ze":
            return {
                title: "Bygg din KANGOO Maxi Z.E.",
                description:
                    "Konfigurer KANGOO Maxi Z.E. etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/kangoo-ze/be-om-tilbud/?c=zhy8d6deofik5mk66px",
            };
        case "kangoo-express":
            return {
                title: "Bygg din KANGOO Express",
                description:
                    "Konfigurer KANGOO Express etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/kangoo-ii-varebil/be-om-tilbud/",
            };
        case "kangoo-maxi":
            return {
                title: "Bygg din KANGOO Express Maxi",
                description:
                    "Konfigurer KANGOO Express Maxi etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/kangoo-ii-varebil/be-om-tilbud/?c=31zppwwtjshk5mjv77v",
            };
        case "nye-trafic":
            return {
                title: "Bygg din TRAFIC",
                description:
                    "Konfigurer TRAFIC etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/trafic-ii-varebil/be-om-tilbud/",
            };
        case "nye-master":
            return {
                title: "Bygg din nye MASTER",
                description:
                    "Konfigurer nye MASTER etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/nye-master/be-om-tilbud/",
            };
        case "nye-express":
            return {
                title: "Bygg din nye Express",
                description:
                    "Konfigurer nye Express etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/express/be-om-tilbud/",
            };
        case "nye-kangoo":
            return {
                title: "Bygg din nye Kangoo",
                description:
                    "Konfigurer nye Kangoo etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/kangoo/be-om-tilbud/",
            };
        case "nye-kangoo-e-tech-electric":
            return {
                title: "Bygg din nye Kangoo E-Tech electric",
                description:
                    "Konfigurer nye Kangoo E-Tech electric etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/kangoo-e-tech-electric/be-om-tilbud/",
            };
        case "megane-e-tech-electric":
            return {
                title: "Bygg din Megane E-Tech electric",
                description:
                    "Konfigurer Megane E-Tech electric etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/megane-e-tech-electric/be-om-tilbud/",
            };
        case "scenic-e-tech-electric":
            return {
                title: "Bygg din Scenic E-Tech electric",
                description:
                    "Konfigurer Scenic E-Tech electric etter ditt ønske. Se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/scenic-e-tech-electric/be-om-tilbud/",
            };
        default:
            return {
                title: "Bygg din Renault",
                description:
                    "Konfigurer bil etter ditt ønske. Velg bil for å se hva som er tilgjengelig av utstyrsnivåer, ekstrautstyr og farger!",
                link: "https://renault.renault.no/biler/",
            };
    }
};

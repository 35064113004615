import styled from "styled-components";

import ArrowLeft from "./icons/ArrowLeft";

const FlexWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    padding-top: 10px;
`;

const BackText = styled.div<{ bold?: boolean }>`
    padding-left: 15px;
    font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

const BackArrowButton = ({ bold }) => {
    return (
        <FlexWrapper>
            <ArrowLeft fill="#EFDF00" width={30} height={30} />
            <BackText bold={bold}>tilbake</BackText>
        </FlexWrapper>
    );
};

export default BackArrowButton;

import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { device } from "../layout/global-styles/device";

interface DoubleParagraphStyledComponent {
    noSpaceTop?: boolean;
}

interface DoubleParagraphProps {
    data: any;
    noSpaceTop?: boolean;
    spaced?: boolean;
}

const DoubleParagraph = ({
    data,
    noSpaceTop = false,
    spaced,
}: DoubleParagraphProps) => {
    return (
        <FlexWrapper noSpaceTop={noSpaceTop}>
            <FlexContent>
                {data[0].title && (
                    <Title>{ReactHtmlParser(data[0].title)}</Title>
                )}
                {data[0].paragraph && spaced && (
                    <>
                        <SpacedParagraph>
                            {ReactHtmlParser(data[0].paragraph)}
                        </SpacedParagraph>
                    </>
                )}
                {data[0].paragraph && !spaced && (
                    <>
                        <Paragraph>
                            {ReactHtmlParser(data[0].paragraph)}
                        </Paragraph>
                    </>
                )}
            </FlexContent>
            <FlexContent>
                {data[1].title && (
                    <Title>{ReactHtmlParser(data[1].title)}</Title>
                )}
                {data[1].paragraph && (
                    <>
                        {!spaced && (
                            <Paragraph>
                                {ReactHtmlParser(data[1].paragraph)}
                            </Paragraph>
                        )}
                        {spaced && (
                            <SpacedParagraph>
                                {ReactHtmlParser(data[1].paragraph)}
                            </SpacedParagraph>
                        )}
                    </>
                )}
            </FlexContent>
        </FlexWrapper>
    );
};

export default DoubleParagraph;

const FlexWrapper = styled.section<DoubleParagraphStyledComponent>`
    display: flex;
    flex-direction: column;
    max-width: 1008px;
    margin: ${(props) => (props.noSpaceTop ? "0 auto" : "6rem auto 0")};
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
        align-items: flex-start;
        flex-direction: row;
    }
`;
const FlexContent = styled.div`
    width: 90%;
    max-width: 700px;

    @media ${device.laptop} {
        width: 45%;
    }
`;
const Title = styled.h3`
    padding: 1rem;
`;
const Paragraph = styled.div`
    padding: 1rem;

    h3 {
        margin: 1.5rem 0 0.3rem;
    }
    a {
        text-decoration: underline;
        text-decoration-color: ${(props) => props.theme.primary};
        text-transform: lowercase;
        color: ${(props) => props.theme.black};
        font-family: "nouvelr-bold";

        &:hover {
            text-decoration-color: ${(props) => props.theme.black};
        }
    }
    ul {
        text-indent: 0.5em;
        padding-left: 1.2em;
    }
`;

const SpacedParagraph = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
`;

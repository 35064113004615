class ModelSection {
    static isTestDrive(): boolean {
        return (
            window.location.hash === "#pr%C3%B8vekj%C3%B8ring" ||
            window.location.hash === "#testdrive"
        );
    }

    static isSecurity(): boolean {
        return (
            window.location.hash === "#sikkerhet" ||
            window.location.hash === "#security"
        );
    }
    static isMenuTestDrive(): boolean {
        return (
            window.location.hash === "#pr%C3%B8vekj%C3%B8r" ||
            window.location.hash === "#testdrivemenu"
        );
    }
    static isAccessories(): boolean {
        return window.location.hash === "#tilbeh%C3%B8r";
    }

    static setLocationHash(hash: string) {
        window.location.hash = hash;
    }
}

export default ModelSection;

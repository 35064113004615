import React from "react";
import styled from "styled-components";

interface IVideoPlatformProps {
    videoPlatform?: string;
    setProgress?: React.Dispatch<React.SetStateAction<number>>;
    setIsSelectedPlatform?: any;

    selectedPlatform?: any;
    setSelectedPlatform?: any;
}

const VideoPlatform = ({
    setIsSelectedPlatform,

    selectedPlatform,
    setSelectedPlatform,
}: IVideoPlatformProps) => {
    const handleSelection = (e) => {
        setIsSelectedPlatform(true);
        setSelectedPlatform(e.target.value);
    };

    const platforms = ["Teams", "Messenger"];
    return (
        <>
            <CardWrapper>
                {platforms &&
                    platforms.map((videoPlatform) => {
                        return (
                            <InfoContainer key={videoPlatform}>
                                <CustomButton>
                                    <RadioButton
                                        type="radio"
                                        checked={
                                            videoPlatform === selectedPlatform
                                        }
                                        value={videoPlatform || ""}
                                        onChange={(e) => handleSelection(e)}
                                        id="selectPlatform"
                                        name="selectPlatform"
                                    />
                                    <RadioButtonToggle />
                                </CustomButton>
                                <AddressInfo>
                                    <Label> {videoPlatform} </Label>
                                </AddressInfo>
                            </InfoContainer>
                        );
                    })}
            </CardWrapper>
        </>
    );
};

export default VideoPlatform;

const CardWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0.5rem 0.2rem 0;
`;

const InfoContainer = styled.label<IVideoPlatformProps>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;

const CustomButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    width: 1.2rem;
    height: 1.2rem;
    border: 0.1rem solid ${(props) => props.theme.black};
    box-shadow: 0px 0px 0px 1px black;
    margin: 0.5rem 1rem 0.5rem 0.1rem;
`;

const RadioButtonToggle = styled.span`
    display: none;
    width: calc(100% - 5px);
    height: calc(100% - 5px);
    border-radius: 50%;
    background: ${(props) => props.theme.black};
`;

const RadioButton = styled.input<IVideoPlatformProps>`
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + ${RadioButtonToggle} {
        display: inline-block;
    }
`;

const AddressInfo = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    margin: 0.5rem 1rem 0.8rem 0;
`;

const Label = styled.p``;

import styled from "styled-components";
import Image from "next/image";
import { device } from "../../../layout/global-styles/device";
import ReactHtmlParser from "react-html-parser";

const ContentCards = ({ contentData }) => {
    const { title, image, text } = contentData;

    return (
        <>
            {contentData && (
                <CardsWrapper>
                    <ContentContainer>
                        {title && (
                            <FeatureTitle>
                                {ReactHtmlParser(title)}
                            </FeatureTitle>
                        )}
                        <FeatureText>{ReactHtmlParser(text)}</FeatureText>
                    </ContentContainer>
                    <ContentContainer>
                        {image && (
                            <ImageDiv>
                                <Image
                                    unoptimized={true}
                                    src={image}
                                    layout="fill"
                                    objectFit="cover"
                                    alt={title}
                                    placeholder="blur"
                                    blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                                />
                            </ImageDiv>
                        )}
                    </ContentContainer>
                </CardsWrapper>
            )}
        </>
    );
};

export default ContentCards;

const CardsWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
    padding: 1rem;
    background-color: ${(props) => props.theme.white};
    width: 100vw;
    height: 100%;
    max-width: 1440px;

    @media ${device.mobileL} {
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 2rem;

        &:nth-child(odd) {
            flex-direction: row-reverse;
        }
    }
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    min-height: 200px;
    flex: 1;
    padding: 5% 2%;

    @media ${device.laptop} {
        max-height: 395px;
        padding: 10% 5%;
    }
`;

const FeatureTitle = styled.h3`
    font-family: "nouvelr-bold";
    color: ${(props) => props.theme.black};
    line-height: 0.9;
    text-align: left;
    font-size: 2rem;
    width: 100%;

    :after {
        content: "";
        display: block;
        margin: 0;
        width: 50%;
        border-bottom: 1px solid black;
        padding-top: 1rem;
    }

    @media ${device.laptop} {
        font-size: 2.125rem;
    }
`;

const FeatureText = styled.p`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    line-height: 1.2;
    font-size: 1rem;

    text-decoration: none;
    list-style: none;

    padding: 1rem 0;

    .duty-text {
        font-size: 0.7rem;
        margin-top: 0.5rem;
    }
`;

const ImageDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

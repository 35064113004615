import { useRouter } from "next/router";
import React from "react";
import styled from "styled-components";
import OfferComponent from "../../OfferComponent";
import TopBanner from "../TopBanner";
import CarColorPicker from "./CarColorPicker/CarColorPicker";
import ContentCards from "./ContentCards";

const ProductOvertake = ({
    showModal,
    setShowModal,
    data,
    carData = undefined,
    designData = undefined,
    sliderData = undefined,
    securityData = undefined,
    testDriveData = undefined,
    accessoriesData = undefined,
    showBuildCar = undefined,
}) => {
    const { title } = data;
    const router = useRouter();

    return (
        <>
            {!securityData && (
                <TopBanner
                    title={title}
                    showModal={showModal}
                    setShowModal={setShowModal}
                />
            )}
            {securityData && (
                <TopBanner
                    title={"SIKKERHET"}
                    showModal={showModal}
                    setShowModal={setShowModal}
                />
            )}

            {!designData &&
                !sliderData &&
                !securityData &&
                !accessoriesData && (
                    <ContentWrapper>
                        {data &&
                            data.media.map((m, i) => (
                                <ContentCards key={m + i} contentData={m} />
                            ))}
                    </ContentWrapper>
                )}
            {designData && (
                <ContentWrapper>
                    {designData &&
                        designData.mediaOT.map((m, i) => (
                            <ContentCards key={m + i} contentData={m} />
                        ))}
                </ContentWrapper>
            )}
            {securityData && data && (
                <ContentWrapper>
                    {securityData.map((m, i) => (
                        <ContentCards key={m + i} contentData={m} />
                    ))}
                </ContentWrapper>
            )}
            {securityData && (
                <OfferComponent
                    carName={"bilen"}
                    testDrive={true}
                    testDriveData={testDriveData}
                    carSlug={router.query.slug.toString()}
                />
            )}
            {carData && <CarColorPicker carData={carData} />}
            {carData && showBuildCar && (
                <OfferComponent
                    carName={carData[0].carName}
                    carSlug={router.query.slug.toString()}
                    carBuilder={true}
                />
            )}
            {accessoriesData && (
                <ContentWrapper>
                    {accessoriesData.map((m, i) => (
                        <ContentCards key={m + i} contentData={m} />
                    ))}
                </ContentWrapper>
            )}
        </>
    );
};

export default ProductOvertake;

const ContentWrapper = styled.div`
    //padding: 10rem 2rem 5rem 2rem;
    /* Detach it from the top banner due to positioning styles */
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    margin: 0 auto;
`;

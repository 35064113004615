import { Formik, Form, useField, useFormikContext } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import styled, { css } from "styled-components";
import * as Yup from "yup";
import axios from "axios";

import DealerContext from "../../../../context/DealerContext";
import { GetCampaignInfo } from "../../../../lib/form-data/campaign-info";
import { TestDriveData } from "../../../../lib/form-data/test-drive";
import { DataLayerPush } from "../../../../lib/tracking/DataLayer";
import { device } from "../../../../layout/global-styles/device";
import { IDealerListData } from "../../../../lib/queries/DealerQuery";
import DealerCard from "./DealerCard";
import FormHeader from "./FormProgress";
import { AdformPush } from "../../../../lib/tracking/AdformPush";
import PostNumberInput from "../PostNumberInput";
import BackupLead from "../../../../lib/form-data/BackupLead";
import SnapchatPush from "../../../../lib/tracking/SnapchatPush";
import FacebookPush from "../../../../lib/tracking/FacebookPush";

interface IFormStyleProps {
    formPlacement?: string;
}

interface IFormComponentProps {
    carData: any;
    postNumber: any;
    setPostNumber: any;
    filteredDealers: IDealerListData;
    selectedDealerForm: IDealerListData;
    setSelectedDealerForm: any;
    dealerPreviouslySelected: boolean;
    setDealerPreviouslySelected: any;
}

const MyTextInput = ({ label, ...props }: any) => {
    const [field, meta] = useField(props);
    return (
        <>
            <Label htmlFor={props.id || props.name}>{label}</Label>
            <Input {...field} {...props} />
            {meta.touched && meta.error ? (
                <StyledErrorMessage>{meta.error}</StyledErrorMessage>
            ) : null}
        </>
    );
};

const SubmitErrorMessage = styled.p`
    color: #cc0000;
`;

const FormComponent = ({
    carData,
    setPostNumber,
    filteredDealers,
    selectedDealerForm,
    setSelectedDealerForm,
    dealerPreviouslySelected,
    setDealerPreviouslySelected,
}: IFormComponentProps) => {
    const [progress, setProgress] = useState(1);
    const [isSelected, setIsSelected] = useState(false);
    const router = useRouter();

    const [submitError, setSubmitError] = useState(null);

    const { selectedDealer } = useContext(DealerContext);
    const [hidePreSelection, setHidePreSelection] = useState(false);

    const formPlacement = carData.formPlacement ?? "right";

    /* 
    Both forms have their own logic inside them, 
    so to avoid refactoring too much we are only fetchinf selectedDealer from the context, 
    which is the one that is setted inside "Finn forhandler"
    */

    const HandleFieldValues = () => {
        const formik = useFormikContext();

        useEffect(() => {
            /* Update "dealer" form value with 
            selected dealer from DealerCard */
            if (isSelected) {
                formik.setFieldValue("dealer", selectedDealerForm.dealerName);
            }

            return () => {
                setIsSelected(false);
            };
        });

        useEffect(() => {
            /* Clear "dealer" form value and checked status 
            when another "search" happens */
            if (dealerPreviouslySelected && !isSelected) {
                formik.setFieldValue("dealer", "");
                setHidePreSelection(true);
            }

            return () => {
                setDealerPreviouslySelected(false);
            };
        });

        useEffect(() => {
            /* Calls postNumber filter function */
            const postNumberValue = formik.getFieldProps("postNumber").value;
            if (postNumberValue.length === 4 && postNumberValue !== "0000") {
                setPostNumber(postNumberValue);
            }
        }, [formik]);

        useEffect(() => {
            /* Updates Progress component based on fields validation */
            const nameField = formik.getFieldMeta("name");
            const emailField = formik.getFieldMeta("email");
            const phoneField = formik.getFieldMeta("phone");
            const dealerField = formik.getFieldMeta("dealer");

            if (
                nameField.touched &&
                !nameField.error &&
                !emailField.error &&
                !phoneField.error &&
                !dealerField.error
            ) {
                setProgress(3);
            } else if (
                nameField.touched &&
                !nameField.error &&
                !emailField.error &&
                !phoneField.error
            ) {
                setProgress(2);
            } else {
                setProgress(1);
            }
        }, [formik]);

        return null;
    };

    const onHandleSubmit = (values: {
        name: string;
        email: string;
        phone: string;
        postNumber: string;
        car: string;
        leadName: string;
        dealer: string;
        depId: string;
        date: string;
    }) => {
        const campaign = GetCampaignInfo();

        const payload = [TestDriveData(values)];
        if (campaign) {
            payload[0].Campaign = campaign;
        }
        if (!payload[0].Campaign) {
            delete payload[0].Campaign;
        }

        if (submitError) {
            setSubmitError(null);
        }

        BackupLead(payload);

        const link = "/api/test-drive";

        axios
            .post(link, payload)
            .then((response) => {
                if (response.status === 200) {
                    const { slug } = router.query;
                    AdformPush(
                        window,
                        41105,
                        "Contact Dealer | Test drive form completed on " +
                            values.car,
                        {}
                    );
                    DataLayerPush(window, {
                        car: values.car,
                        dealer: values.dealer,
                        event: "formSubmitTestDrive",
                    });
                    SnapchatPush("track", "PAGE_VIEW");
                    FacebookPush("track", "formSubmitTestDrive");
                    router.push(`/prøvekjør/${slug}/takk?slug=${slug}`);
                }
            })
            .catch((error) => {
                console.error(error);
                setSubmitError(
                    new Error("Feil ved innsending, vennligst prøv igjen!")
                );
            });
    };

    return (
        <Formik
            enableReinitialize
            initialValues={{
                name: "",
                email: "",
                phone: "",
                postNumber: "",
                car: "",
                leadName: "",
                dealer: selectedDealer ? selectedDealer.dealerName : "",
                depId: selectedDealer ? selectedDealer.depId : "",
                date: "",
            }}
            validationSchema={Yup.object({
                name: Yup.string()
                    .min(3, "Må bestå av minst 3 tegn.")
                    .required("Vennligst oppgi navnet ditt"),
                email: Yup.string()
                    .email("Vennligst oppgi en korrekt e-post adresse.")
                    .required("Vennligst oppgi en korrekt e-post adresse."),
                phone: Yup.string()
                    .min(8, "Må bestå av minst 8 tegn.")
                    .required("Vennligst oppgi et gyldig telefonnummer."),
                postNumber: Yup.string()
                    .min(4, "Korrekt postnummer må bestå av 4 tegn.")
                    .max(4, "Korrekt postnummer må bestå av 4 tegn.")
                    .required("Vennligst oppgi et gyldig postnummer."),
                car: Yup.string(),
                leadName: Yup.string(),
                dealer: Yup.string().required(),
                depId: Yup.string().required(),
                date: Yup.date(),
            })}
            onSubmit={onHandleSubmit}
        >
            {({
                values,
                isSubmitting,
                handleSubmit,
                isValid,
                dirty,
                setFieldValue,
            }) => (
                <>
                    <FormWrapper formPlacement={formPlacement}>
                        <HandleFieldValues />
                        <StyledForm onSubmit={handleSubmit}>
                            <FormHeader carData={carData} progress={progress} />
                            <InputGroup>
                                <MyTextInput
                                    label="Navn: *"
                                    as={Input}
                                    name="name"
                                    placeholder="Kari Nordmann"
                                    type="input"
                                />
                            </InputGroup>
                            <InputGroup>
                                <MyTextInput
                                    label="E-postadresse: *"
                                    as={Input}
                                    name="email"
                                    placeholder="karinordmann@gmail.com"
                                    type="input"
                                />
                            </InputGroup>
                            <InputGroup>
                                <MyTextInput
                                    label="Telefon: *"
                                    as={Input}
                                    name="phone"
                                    placeholder="900 90 900"
                                    type="phone"
                                    min="0"
                                />
                            </InputGroup>

                            <InputGroup>
                                <Label>
                                    Skriv inn ditt postnummer, så finner vi
                                    forhandleren som er nærmest deg.
                                </Label>

                                <PostNumberInput
                                    label="Postnummer:"
                                    name="postNumber"
                                    as={Input}
                                    placeholder=""
                                    value={values.postNumber}
                                    type="text"
                                    inputmode="numeric"
                                    min="0"
                                    pattern="[0-9]*"
                                />
                            </InputGroup>
                            <InputGroup>
                                <Label>Valgt forhandler:</Label>
                            </InputGroup>
                            <DealersContainer>
                                {values.postNumber.length >= 4 &&
                                    filteredDealers &&
                                    filteredDealers.map((dealer) => {
                                        if (
                                            dealer.services.includes(
                                                "forhandler"
                                            )
                                        ) {
                                            return (
                                                <DealerCard
                                                    key={dealer.depId}
                                                    dealer={dealer}
                                                    setSelectedDealer={() => {
                                                        setSelectedDealerForm(
                                                            dealer
                                                        );
                                                        setFieldValue(
                                                            "dealer",
                                                            dealer.dealerName
                                                        );
                                                        setFieldValue(
                                                            "depId",
                                                            dealer.depId
                                                        );
                                                    }}
                                                    setProgress={setProgress}
                                                    setIsSelected={
                                                        setIsSelected
                                                    }
                                                    radioChecked={
                                                        selectedDealerForm
                                                            ? selectedDealerForm.depId ===
                                                              dealer.depId
                                                            : false
                                                    }
                                                    setRadioChecked={() => {}}
                                                />
                                            );
                                        }
                                    })}
                                {selectedDealer && !hidePreSelection && (
                                    <>
                                        {selectedDealer.services.includes(
                                            "forhandler"
                                        ) && (
                                            <DealerCard
                                                dealer={selectedDealer}
                                                setSelectedDealer={(dealer) => {
                                                    setFieldValue(
                                                        "dealer",
                                                        dealer.dealerName
                                                    );
                                                    setFieldValue(
                                                        "depId",
                                                        dealer.depId
                                                    );
                                                    setSelectedDealerForm(
                                                        dealer
                                                    );
                                                }}
                                                setProgress={setProgress}
                                                setIsSelected={setIsSelected}
                                                radioChecked={
                                                    selectedDealer
                                                        ? true
                                                        : false
                                                }
                                                setRadioChecked={() => {}}
                                            />
                                        )}
                                    </>
                                )}
                            </DealersContainer>

                            <input
                                type="hidden"
                                name="car"
                                value={values.car}
                            />
                            <input
                                type="hidden"
                                name="leadName"
                                value={values.leadName}
                            />
                            <input
                                type="hidden"
                                name="dealer"
                                value={values.dealer}
                            />
                            <input
                                type="hidden"
                                name="depId"
                                value={values.depId}
                            />
                            <input
                                type="hidden"
                                name="date"
                                value={values.date}
                            />

                            {submitError && (
                                <SubmitErrorMessage>
                                    {submitError.message}
                                </SubmitErrorMessage>
                            )}
                            <Submit
                                type="submit"
                                disabled={!(isValid && dirty) || isSubmitting}
                                onClick={() => {
                                    setFieldValue("car", carData.name);
                                    setFieldValue("leadName", carData.leadName);
                                    setFieldValue("date", new Date());
                                }}
                            >
                                book prøvekjøring
                            </Submit>
                        </StyledForm>
                    </FormWrapper>
                </>
            )}
        </Formik>
    );
};

export default FormComponent;

const FormWrapper = styled.div<IFormStyleProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${(props) =>
        props.formPlacement.includes("left") &&
        css`
            margin-right: auto;
        `}
    ${(props) =>
        props.formPlacement.includes("center") &&
        css`
            margin: 0 auto;
        `}
    ${(props) =>
        props.formPlacement.includes("right") &&
        css`
            margin-left: auto;
        `}
`;

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 1rem 2rem;
    padding: 1rem;
    background-color: ${(props) => props.theme.white};

    @media ${device.tablet} {
        width: 70%;
    }

    @media ${device.laptop} {
        width: 60%;
        margin: 1rem 4rem;
        padding: 1rem 2.5rem;
    }
`;

const InputGroup = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 0.4rem 0;
`;

const DealersContainer = styled(InputGroup)`
    min-height: 1rem;
`;

const Input = styled.input`
    font-family: "nouvelr-regular";
    padding: 0.6rem 1rem;
    width: 100%;
    margin: 0.2rem 0;
    border: 1px solid transparent;
    border-bottom: 1px solid ${(props) => props.theme.medium};

    ::placeholder {
        color: ${(props) => props.theme.medium};
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    [type="number"] {
        -moz-appearance: textfield; /* Firefox */
    }
`;
const Label = styled.label`
    text-align: left;
    margin: 0.2rem 0;
`;

const Submit = styled.button`
    width: 100%;
    margin: 2.5rem 0;
    cursor: pointer;
    padding: 1rem;
    border: 1px solid transparent;
    font-size: 1.15rem;

    background-color: ${(props) => props.theme.primary};

    &:disabled {
        background-color: ${(props) => props.theme.medium};
    }
`;

const StyledErrorMessage = styled.div`
    position: absolute;
    bottom: -10px;
    font-size: 0.7rem;
    color: #cc0000;
    width: 100%;
    margin-top: 0.25rem;
`;

import { useField } from "formik";

import StyledErrorMessage from "./StyledErrorMessage";
import Label from "./Label";
import Input from "./Input";

const PostNumberInput = ({ label, ...props }: any) => {
    const [field, meta, helpers] = useField(props);
    return (
        <>
            <Label htmlFor={props.id || props.name}>{label}</Label>
            <Input
                {...field}
                {...props}
                onChange={(event) => {
                    helpers.setValue(
                        event.target.value.trim().replace(/\D/g, "")
                    );
                }}
            />
            {meta.touched && meta.error ? (
                <StyledErrorMessage>{meta.error}</StyledErrorMessage>
            ) : null}
        </>
    );
};

export default PostNumberInput;

export const GetCampaignInfo = () => {
    let campaing = {
        Source: localStorage.getItem("utm_source"),
        Medium: localStorage.getItem("utm_medium"),
        Campaign: localStorage.getItem("utm_campaign"),
        Content: localStorage.getItem("utm_content"),
    };

    let lastUpdated = localStorage.getItem("__lastUpdated");
    if (!lastUpdated) {
        return null;
    }
    /* @ts-ignore */
    lastUpdated = new Date(lastUpdated);
    if (!lastUpdated) {
        return null;
    }
    /* @ts-ignore */
    const diffTime = Math.abs(
        /* @ts-ignore */
        new Date() - lastUpdated
    );
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 60) {
        return null;
    }

    let arr = ["vg", "db", "ta"];
    for (const i of arr) {
        if (campaing.Source && campaing.Source == i) {
            campaing.Source = i + ".no";
        }
    }
    return campaing;
};

import React, { ReactNode, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { componentTypes } from ".";
import TestDriveForm from "./Forms/TestDrive";
import VideoViewingForm from "./Forms/VideoViewing";
import InsuranceOvertake from "./OwnersOvertake/InsuranceOvertake";
import ServiceOvertake from "./OwnersOvertake/ServiceOvertake";
import ProductOvertake from "./ProductOvertake/ProductOvertake";

import SlotTakeOver from "./SlotTakeOver";

interface PageOvertakeProps {
    topImage?: string;
    title?: string;
    componentType?: componentTypes;
    showModal: any;
    setShowModal: any;
    data: any;
    carData: any;
    designData: any;
    sliderData: any;
    securityData: any;
    testDriveData?: any;
    showBuildCar?: any;
    accessoriesData?: any;
    children?: ReactNode;
}

const OvertakeWrapper = ({
    topImage,
    title,
    showModal,
    setShowModal,
    data,
    carData = undefined,
    componentType = undefined,
    designData = undefined,
    sliderData = undefined,
    securityData = undefined,
    testDriveData = undefined,
    showBuildCar = undefined,
    accessoriesData = undefined,
    children = null,
}: PageOvertakeProps) => {
    const modalRef = useRef();

    const closeModal = (e) => {
        /* Close modal when clicking outside the container */
        if (modalRef.current === e.target) {
            setShowModal(false);
        }
    };

    const keyPress = useCallback(
        /* Close modal when ESC key is pressed */
        (e) => {
            if (e.key === "Escape" && showModal) {
                setShowModal(false);
            }
        },
        [setShowModal, showModal]
    );

    useEffect(() => {
        /* Call and cleanup function */
        document.addEventListener("keydown", keyPress);
        return () => {
            document.removeEventListener("keydown", keyPress);
        };
    }, [keyPress]);

    return (
        <>
            {showModal ? (
                <Background ref={modalRef} onClick={closeModal}>
                    <ModalWrapper>
                        <>
                            {componentType === "forsikring-og-finansiering" && (
                                <InsuranceOvertake
                                    {...{ data, showModal, setShowModal }}
                                />
                            )}
                            {componentType.match(/service/) && (
                                <>
                                    <ServiceOvertake
                                        {...{ data, showModal, setShowModal }}
                                    />
                                </>
                            )}
                            {componentType === "product" && (
                                <ProductOvertake
                                    showModal={showModal}
                                    setShowModal={setShowModal}
                                    data={data}
                                    carData={carData}
                                    designData={designData}
                                    sliderData={sliderData}
                                    securityData={securityData}
                                    showBuildCar={showBuildCar}
                                    testDriveData={testDriveData}
                                    accessoriesData={accessoriesData}
                                />
                            )}
                            {componentType === "testdrive" && (
                                <TestDriveForm
                                    showModal={showModal}
                                    setShowModal={setShowModal}
                                    data={data}
                                    carData={carData}
                                />
                            )}
                            {componentType === "videoviewing" && (
                                <VideoViewingForm
                                    showModal={showModal}
                                    setShowModal={setShowModal}
                                    data={data}
                                    carData={carData}
                                />
                            )}
                            {componentType === "other" && (
                                <SlotTakeOver
                                    title={title}
                                    showModal={showModal}
                                    setShowModal={setShowModal}
                                    topImage={topImage}
                                >
                                    {children}
                                </SlotTakeOver>
                            )}
                        </>
                    </ModalWrapper>
                </Background>
            ) : null}
        </>
    );
};

export default OvertakeWrapper;

const Background = styled.div`
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ModalWrapper = styled.div`
    min-height: 100vh;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100vh;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: ${(props) => props.theme.white};
    color: ${(props) => props.theme.black};
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 100;
    overflow-y: auto;
`;

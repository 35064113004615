import React from "react";
import styled from "styled-components";

interface IDealerCardProps {
    dealer?: any;
    setSelectedDealer?: React.Dispatch<React.SetStateAction<any>>;
    setProgress?: React.Dispatch<React.SetStateAction<number>>;
    setIsSelected?: any;
    radioChecked?: any;
    setRadioChecked?: any;
}

const DealerCard = ({
    dealer,
    setSelectedDealer,
    setIsSelected,
    radioChecked,
    setRadioChecked,
}: IDealerCardProps) => {
    const handleSelection = () => {
        setSelectedDealer(dealer);
        setIsSelected(true);
        setRadioChecked(true);
    };

    return (
        <>
            <CardWrapper>
                <InfoContainer className={radioChecked ? "active" : ""}>
                    <CustomButton>
                        <RadioButton
                            type="radio"
                            checked={radioChecked}
                            value={dealer.dealerName || ""}
                            onChange={() => handleSelection()}
                            id="selectDealer"
                            name="selectDealer"
                        />
                        <RadioButtonToggle />
                    </CustomButton>
                    <AddressInfo>
                        <DealerName> {dealer.dealerName} </DealerName>
                        <DealerAddress>
                            {`${dealer.dealerAddress.address}, ${dealer.dealerAddress.postNumber} ${dealer.dealerAddress.postalCode}`}
                        </DealerAddress>
                    </AddressInfo>
                </InfoContainer>
            </CardWrapper>
        </>
    );
};

export default DealerCard;

const CardWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0 0 1rem 0;
    border: 1px solid ${(props) => props.theme.black};

    .active {
        border-top: 3px solid ${(props) => props.theme.primary};
    }
`;

const InfoContainer = styled.label<IDealerCardProps>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-top: 3px solid transparent;
`;

const CustomButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    width: 1.2rem;
    height: 1.2rem;
    border: 0.1rem solid ${(props) => props.theme.black};
    box-shadow: 0px 0px 0px 1px black;
    margin: 0.5rem 1rem;
`;

const RadioButtonToggle = styled.span`
    display: none;
    width: calc(100% - 5px);
    height: calc(100% - 5px);
    border-radius: 50%;
    background: ${(props) => props.theme.black};
`;

const RadioButton = styled.input<IDealerCardProps>`
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + ${RadioButtonToggle} {
        display: inline-block;
    }
`;

const AddressInfo = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    margin: 0.5rem 1rem 0.8rem 0;
`;

const DealerName = styled.p`
    font-family: "nouvelr-bold";
    font-size: 1.25rem;
`;

const DealerAddress = styled.p`
    font-family: "nouvelr-regular";
    color: ${(props) => props.theme.base};
`;

import { getTime } from "../form-get-time";
import format from "date-fns/format";

export const ServiceData = ({
    depId,
    dealer,
    name,
    email,
    phone,
    car,
    // addresse,
    comments,
    km,
    date,
    serviceSelected,
    modelOther,
    registrationNumber,
    postNumber,
}) => {
    const timeFMT = format(date, "HH:mm");
    const dateFMT = format(date, "dd.MM.yyyy");

    return {
        ExtID: "1",
        SChemaID: "210",
        SchemaName: "Verkstedhenvendelse",
        CodesetID: "Fortress01",
        RegistrationDate: getTime().toString(),
        DepartmentID: depId,
        DepartmentName: dealer,
        InquiryTypeID: "Verkstedhenvendelse",
        InquiryTypeDescription: "Verkstedhenvendelse",
        Name: name,
        Email: email,
        Mobile: phone,
        HTTPlink: window.location.href,
        Zipcode: postNumber,
        CommentList: [
            {
                CommentDescription: "Forhandler",
                Comment: dealer,
            },
            {
                CommentDescription: "Kilometerstand", //constant value
                Comment: km,
            },
            {
                CommentDescription: "Tilleggsinformasjon", //constant value
                Comment: comments,
            },
            {
                CommentDescription: "Foretrukket dato og tidspunkt", //constant value
                Comment:
                    "Foretrukket dato : " + dateFMT + " & tid : " + timeFMT,
            },
            {
                CommunicationConsent: {
                    RegistrationDate: getTime().toString(), //reg date
                    PrivacyTerms: true, // Assumed true when user has reached this far
                },
            },
        ],
        Product: [
            {
                ProductType: serviceSelected,
                Maingroup: "Renault",
                Subset: car,
                Description: modelOther,
                Productnumber: registrationNumber,
            },
        ],
        Campaign: {},
    };
};

import { getTime } from "../form-get-time";

export const VideoViewingData = ({
    name,
    email,
    phone,
    videoPlatform,
    car,
    dealer,
    depId,
    postNumber,
}) => {
    return {
        ExtID: "1",
        SChemaID: "20",
        SchemaName: "DigitalConsulting",
        CodesetID: "Fortress01",
        RegistrationDate: getTime().toString(),
        DepartmentID: depId,
        DepartmentName: dealer,
        InquiryTypeID: "DigitalConsulting",
        InquiryTypeDescription: "Digital konsultasjon",
        Name: name,
        Email: email,
        Mobile: phone,
        Zipcode: postNumber,
        HTTPlink: window.location.href,
        CommentList: [
            {
                CommentDescription: "Forhandler", //constant value
                Comment: depId,
            },
            {
                CommentDescription: "platform", //constant value
                Comment: videoPlatform,
            },
        ],
        Product: [
            {
                ProductType: "newcar",
                Maingroup: "Renault",
                Subset: car,
            },
        ],
        Campaign: {},
    };
};

import styled from "styled-components";

const StyledErrorMessage = styled.div`
    position: absolute;
    bottom: -10px;
    font-size: 0.7rem;
    color: #cc0000;
    width: 100%;
    margin-top: 0.25rem;
`;

export default StyledErrorMessage;

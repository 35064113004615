const MapPin = ({fill, width, height}) => {
    return (
        <svg version="1.1" height={height} width={width} fill={fill}  x="0px" y="0px" viewBox="0 0 60 60">
<g>
	<path d="M42.779,25.617c0,6.18-4.14,13.799-11.7,21.479h-2.159c-7.56-7.68-11.7-15.299-11.7-21.479c0-7.859,4.92-12.72,12.78-12.72
		C37.859,12.897,42.779,17.757,42.779,25.617z M40.678,25.617c0-6.6-4.079-10.68-10.679-10.68c-6.54,0-10.68,4.08-10.68,10.68
		c0,5.58,3.6,12.18,10.68,19.499C37.079,37.796,40.678,31.197,40.678,25.617z M29.999,20.396c3.18,0,5.159,2.041,5.159,5.221
		s-1.979,5.219-5.159,5.219c-3.181,0-5.16-2.039-5.16-5.219S26.819,20.396,29.999,20.396z M29.999,22.437
		c-1.86,0-3.12,1.26-3.12,3.18s1.26,3.18,3.12,3.18c1.92,0,3.119-1.26,3.119-3.18S31.919,22.437,29.999,22.437z"/>
</g>
</svg>
    );
}

export default MapPin;
import styled from "styled-components";
import {
    ContentWrapper,
    ImageWrapper,
    TopImage,
} from "./OwnersOvertake/ServiceOvertake";
import TopBanner from "./TopBanner";

export const DescriptionWrapper = styled.div``;

const SlotTakeOver = ({
    topImage,
    title,
    showModal,
    setShowModal,
    children,
}) => {
    return (
        <>
            {title && (
                <TopBanner
                    title={title || ""}
                    showModal={showModal}
                    setShowModal={setShowModal}
                />
            )}
            {!title && (
                <CloseIcon onClick={() => setShowModal(!showModal)}>
                    <div />
                    <div />
                </CloseIcon>
            )}
            <ContentWrapper>
                {topImage && (
                    <ImageWrapper>
                        <TopImage
                            unoptimized={true}
                            src={topImage}
                            width={1920}
                            height={700}
                            objectFit="cover"
                            loading="eager"
                            objectPosition="50% 50%"
                            placeholder="blur"
                            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAQAAAAm93DmAAAAJklEQVR42u3MMQEAAAgDoC251Q2hJwSgmbyqUCgUCoVCoVAoFN4spocUAbrGeLoAAAAASUVORK5CYII="
                        />
                    </ImageWrapper>
                )}
                <DescriptionWrapper>{children}</DescriptionWrapper>
            </ContentWrapper>
        </>
    );
};

export default SlotTakeOver;

const CloseIcon = styled.button`
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    background: transparent;
    border: none;
    cursor: pointer;

    top: 5%;
    right: 5%;
    position: absolute;

    z-index: 100;

    width: 24px;
    height: 24px;

    div {
        width: 28px;
        height: 3px;
        background: ${(props) => props.theme.black};
        border-radius: 5px;
        transform-origin: 5px;
        position: relative;
        transition: 200ms ease;

        &:nth-child(1) {
            transform: rotate(45deg);
        }

        &:nth-child(2) {
            transform: rotate(-45deg);
        }
    }

    @media (min-width: 900px) {
        width: 24px;
        height: 24px;

        div {
            width: 28px;
            height: 3px;
        }
    }
`;

import axios from "axios";

const BackupLead = (payload, url?) => {
    try {
        let formData = new FormData();
        for (const key in payload[0]) {
            formData.append(
                key,
                key.toLowerCase() !== "email"
                    ? JSON.stringify(payload[0][key])
                    : payload[0][key]
            );
        }
        /* @ts-ignore */
        axios({
            method: "post",
            url: url || "https://formspree.io/f/xyyladvp",
            data: formData,
        });
    } catch (error) {
        console.error(error);
    }
};

export default BackupLead;

import { Formik, Form, useField, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";

// The date picker stuff (styling, times and localization)
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import nb from "date-fns/locale/nb";

import { IDealerListData } from "../../../../lib/queries/DealerQuery";
import { device } from "../../../../layout/global-styles/device";
import axios from "axios";
import { useRouter } from "next/router";
import DealerCard from "../TestDrive/DealerCard";
import { ServiceData } from "../../../../lib/form-data/service-data";
// import CalendarIcon from "../../../icons/CalendarIcon";
import calendarIcon from "../../../../public/icons/calendar-icon.png";
import Image from "next/image";
import { DataLayerPush } from "../../../../lib/tracking/DataLayer";
import { AdformPush } from "../../../../lib/tracking/AdformPush";
import FacebookPush from "../../../../lib/tracking/FacebookPush";
import PostNumberInput from "../PostNumberInput";
import BackupLead from "../../../../lib/form-data/BackupLead";

interface IFormComponentProps {
    availableCars: any;
    services: any;
    postNumber: any;
    setPostNumber: any;
    filteredDealers: IDealerListData;
    selectedDealer: IDealerListData;
    setSelectedDealer: any;
    dealerPreviouslySelected: boolean;
    setDealerPreviouslySelected: any;
    selectedService: string;
}

const MyTextInput = ({ label, ...props }: any) => {
    const [field, meta] = useField(props);
    return (
        <>
            <Label htmlFor={props.id || props.name}>{label}</Label>
            <Input {...field} {...props} />
            {meta.touched && meta.error ? (
                <StyledErrorMessage>{meta.error}</StyledErrorMessage>
            ) : null}
        </>
    );
};

const SelectField = ({ label, ...props }: any) => {
    const [field, meta] = useField(props);
    return (
        <>
            <Label htmlFor={props.id || props.name}>{label}</Label>
            <StyledSelect {...field} {...props} />
            {meta.touched && meta.error ? (
                <StyledErrorMessage>{meta.error}</StyledErrorMessage>
            ) : null}
        </>
    );
};

const StyledDatePicker = styled.div`
    width: 100%;
    input {
        font-family: "nouvelr-regular";
        padding: 0.8rem 1rem;
        width: 100%;
        margin: 0.2rem 0;

        cursor: pointer;
        border: 1px solid transparent;
        border-bottom: 1px solid ${(props) => props.theme.medium};
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    position: relative;
`;

const CalendarIcon = styled.div`
    position: absolute;
    right: 10%;
    top: 8px;
    cursor: pointer;
`;

const DatePickerField = ({ ...props }: any) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(props);
    const today = Date.now();
    return (
        <StyledDatePicker>
            <label>
                <DatePicker
                    {...field}
                    {...props}
                    selected={(field.value && new Date(field.value)) || null}
                    minDate={today}
                    onChange={(val) => {
                        setFieldValue(field.name, val);
                    }}
                />

                <CalendarIcon>
                    <Image
                        unoptimized={true}
                        alt="kalender"
                        src={calendarIcon}
                        height={32}
                        width={32}
                    />
                </CalendarIcon>
            </label>
            {meta.touched && meta.error ? (
                <StyledErrorMessage>{meta.error}</StyledErrorMessage>
            ) : null}
        </StyledDatePicker>
    );
};

const StyledTextArea = styled.textarea`
    font-family: "nouvelr-regular";
    padding: 0.8rem 1rem;
    width: 100%;
    margin: 0.2rem 0;
    resize: none;

    border: 1px solid transparent;
    border-bottom: 1px solid ${(props) => props.theme.medium};

    ::placeholder {
        color: ${(props) => props.theme.medium};
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const MyTextArea = ({ label, ...props }: any) => {
    const [field, meta] = useField(props);
    return (
        <>
            <Label htmlFor={props.id || props.name}>{label}</Label>
            <StyledTextArea {...field} {...props} />
            {meta.touched && meta.error ? (
                <StyledErrorMessage>{meta.error}</StyledErrorMessage>
            ) : null}
        </>
    );
};

const SubmitErrorMessage = styled.p`
    color: #cc0000;
`;

const includeTimes = (() => {
    let times = [];
    for (let i = 7; i < 18; i++) {
        times.push(setHours(setMinutes(new Date(), 0), i));
    }
    return times;
})();

const FormComponent = ({
    availableCars,
    services,
    setPostNumber,
    filteredDealers: dealers,
    selectedService,
    selectedDealer,
    setSelectedDealer,
    dealerPreviouslySelected,
    setDealerPreviouslySelected,
}: IFormComponentProps) => {
    const [isSelected, setIsSelected] = useState(false);
    const router = useRouter();

    const [submitError, setSubmitError] = useState(null);

    const HandleFieldValues = () => {
        const formik = useFormikContext();

        useEffect(() => {
            /* Update "dealer" form value with 
            selected dealer from DealerCard */
            if (isSelected) {
                formik.setFieldValue("dealer", selectedDealer.dealerName);
            }

            return () => {
                setIsSelected(false);
            };
        });

        useEffect(() => {
            /* Clear "dealer" form value and checked status 
            when another "search" happens */
            if (dealerPreviouslySelected && !isSelected) {
                formik.setFieldValue("dealer", "");
            }

            return () => {
                setDealerPreviouslySelected(false);
            };
        });

        useEffect(() => {
            /* Calls postNumber filter function */
            const postNumberValue = formik.getFieldProps("postNumber").value;
            if (postNumberValue.length === 4 && postNumberValue !== "0000") {
                setPostNumber(postNumberValue);
            }
        }, [formik]);

        return null;
    };

    const onHandleSubmit = (values: {
        name: string;
        email: string;
        phone: string;
        postNumber: string;
        dealer: string;
        depId: string;
        date: string;
        serviceSelected: string;
        car: string;
        comments: string;
        registrationNumber: string;
        km: string;
        modelOther: string;
    }) => {
        const payload = [ServiceData(values)];
        if (submitError) {
            setSubmitError(null);
        }

        BackupLead(payload);

        const link =
            window.location.hostname != "renault.no"
                ? "https://acceptlead-test.motorforum.no/api/lead"
                : "https://acceptlead.motorforum.no/api/lead";

        axios
            .post(link, payload, {
                headers: {
                    "X-Token":
                        "8395936f492cac4c7e0db781e894ff21290c8a6c04e914cda0458c634134f857041bba82f9342d575c63e7c8c837e4b5",
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    AdformPush(window, 1675767, "Verkstedtime", {});
                    FacebookPush("track", "Verkstedtime");
                    DataLayerPush(window, {
                        car: values.car,
                        dealer: values.dealer,
                        event: "formSubmitTestDrive",
                    });
                    router.push(`/optimalservice/takk`);
                }
            })
            .catch((error) => {
                console.error(error);
                setSubmitError(
                    new Error("Feil ved innsending, vennligst prøv igjen!")
                );
            });
    };

    return (
        <Formik
            enableReinitialize
            initialValues={{
                name: "",
                email: "",
                phone: "",
                postNumber: "",
                car: "",
                dealer: "",
                depId: "",
                date: "",
                serviceSelected: selectedService ?? "",
                comments: "",
                registrationNumber: "",
                km: "",
                modelOther: "",
            }}
            validationSchema={Yup.object({
                name: Yup.string()
                    .min(3, "Må bestå av minst 3 tegn.")
                    .required("Vennligst oppgi navnet ditt."),
                email: Yup.string()
                    .email("Vennligst oppgi gyldig e-post adresse.")
                    .required("Vennligst oppgi gyldig e-post adresse."),
                phone: Yup.string()
                    .min(8, "Må bestå av minst 8 tegn.")
                    .required("Vennligst oppgi gyldig telefonnummer."),
                postNumber: Yup.string()
                    .min(4, "Korrekt postnummer må bestå av 4 tegn.")
                    .max(4, "Korrekt postnummer må bestå av 4 tegn.")
                    .required("Vennligst oppgi gyldig postnummer."),
                car: Yup.string().required("Vennligst velg modell."),
                dealer: Yup.string().required(),
                depId: Yup.string().required(),
                date: Yup.date().required(
                    "Vennligst oppgi foretrukket dato og tid."
                ),
                serviceSelected: Yup.string().required(
                    "Vennligst velg type henvendelse."
                ),
                comments: Yup.string(),
                registrationNumber: Yup.string().required(
                    "Vennligst oppgi registreringsnummer."
                ),
                km: Yup.string(),
                modelOther: Yup.string(),
            })}
            onSubmit={onHandleSubmit}
        >
            {({
                values,
                isSubmitting,
                handleSubmit,
                isValid,
                dirty,
                setFieldValue,
            }) => (
                <>
                    <FormWrapper>
                        <HandleFieldValues />
                        <StyledForm onSubmit={handleSubmit}>
                            <InputGroup>
                                <MyTextInput
                                    label="Navn: *"
                                    as={Input}
                                    name="name"
                                    placeholder="Kari Nordmann"
                                    type="input"
                                />
                            </InputGroup>
                            <InputGroup>
                                <MyTextInput
                                    label="E-postadresse: *"
                                    as={Input}
                                    name="email"
                                    placeholder="karinordmann@gmail.com"
                                    type="input"
                                />
                            </InputGroup>
                            <InputGroup>
                                <MyTextInput
                                    label="Telefon: *"
                                    as={Input}
                                    name="phone"
                                    placeholder="900 90 900"
                                    type="phone"
                                    min="0"
                                />
                            </InputGroup>

                            <InputGroup>
                                <Label>
                                    Skriv inn ditt postnummer, så finner vi
                                    forhandleren som er nærmest deg.
                                </Label>

                                <PostNumberInput
                                    label="Postnummer:"
                                    name="postNumber"
                                    as={Input}
                                    placeholder=""
                                    value={values.postNumber}
                                    type="text"
                                    inputmode="numeric"
                                    min="0"
                                    pattern="[0-9]*"
                                />
                            </InputGroup>
                            <InputGroup>
                                <Label>Valgt forhandler:</Label>
                            </InputGroup>
                            <DealersContainer>
                                {values.postNumber.length >= 4 &&
                                    dealers &&
                                    dealers.map((dealer) => {
                                        if (
                                            dealer.depId != "Autoservice Moss"
                                        ) {
                                            return (
                                                <DealerCard
                                                    key={dealer.depId}
                                                    dealer={dealer}
                                                    setSelectedDealer={(
                                                        dealer
                                                    ) => {
                                                        setFieldValue(
                                                            "dealer",
                                                            dealer.dealerName
                                                        );
                                                        setFieldValue(
                                                            "depId",
                                                            dealer.depId
                                                        );
                                                        setSelectedDealer(
                                                            dealer
                                                        );
                                                    }}
                                                    setIsSelected={
                                                        setIsSelected
                                                    }
                                                    radioChecked={
                                                        selectedDealer
                                                            ? selectedDealer.depId ===
                                                              dealer.depId
                                                            : false
                                                    }
                                                    setRadioChecked={() => {}}
                                                />
                                            );
                                        }
                                    })}
                            </DealersContainer>

                            <InputGroup>
                                <SelectField
                                    label="Hva gjelder henvendelsen?: *"
                                    name="serviceSelected"
                                    as="select"
                                    value={values.serviceSelected}
                                    onChange={(event) => {
                                        setFieldValue(
                                            "serviceSelected",
                                            event.target.value
                                        );
                                    }}
                                >
                                    <option></option>
                                    {services.map((service) => (
                                        <option
                                            value={service.titel}
                                            key={service.slug}
                                        >
                                            {service.titel}
                                        </option>
                                    ))}
                                </SelectField>
                            </InputGroup>
                            <InputGroup>
                                <SelectField
                                    label="Velg modell: *"
                                    name="car"
                                    as="select"
                                    onChange={(event) => {
                                        setFieldValue(
                                            "car",
                                            event.target.value
                                        );
                                    }}
                                >
                                    <option></option>
                                    {availableCars.map((car) => (
                                        <option
                                            value={car.leadName}
                                            key={car.title}
                                        >
                                            {car.title}
                                        </option>
                                    ))}
                                </SelectField>

                                {/* <Label>Velg modell: *</Label>
                                <StyledSelect
                                    name="car"
                                    onChange={(event) => {
                                        setFieldValue(
                                            "car",
                                            event.target.value
                                        );
                                    }}
                                >
                                    <option></option>
                                    {availableCars.map((car) => (
                                        <option value={car.name} key={car.name}>
                                            {car.name}
                                        </option>
                                    ))}
                                </StyledSelect> */}
                            </InputGroup>

                            <InputGroup>
                                <MyTextInput
                                    label="Registreringsnummer: *"
                                    as={Input}
                                    name="registrationNumber"
                                    placeholder="EV 12345"
                                    type="input"
                                />
                            </InputGroup>

                            <InputGroup>
                                <MyTextInput
                                    label="Kilometerstand:"
                                    as={Input}
                                    name="km"
                                    placeholder="120 000 km"
                                    type="input"
                                />
                            </InputGroup>

                            <InputGroup>
                                <Label>Foretrukket dato og tid: *</Label>
                                <DatePickerField
                                    value={values.date}
                                    label="Foretrukket dato og tid: *"
                                    name="date"
                                    placeholder="10.06.2021 / KL: 12:00"
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    dateFormat="dd.MM.yyyy / HH:mm"
                                    includeTimes={includeTimes}
                                    timeIntervals={60}
                                    locale={nb}
                                    showIcon={true}
                                />
                            </InputGroup>

                            <InputGroup>
                                <MyTextArea
                                    label="Tileggsinformasjon: "
                                    name="comments"
                                    placeholder="annet"
                                    rows="6"
                                ></MyTextArea>
                            </InputGroup>

                            <input
                                type="hidden"
                                name="dealer"
                                value={values.dealer}
                            />
                            <input
                                type="hidden"
                                name="depId"
                                value={values.depId}
                            />

                            {submitError && (
                                <SubmitErrorMessage>
                                    {submitError.message}
                                </SubmitErrorMessage>
                            )}
                            <Submit
                                type="submit"
                                disabled={!(isValid && dirty) || isSubmitting}
                            >
                                bestill verkstedtime
                            </Submit>
                        </StyledForm>
                    </FormWrapper>
                </>
            )}
        </Formik>
    );
};

export default FormComponent;

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
`;

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 1rem 2rem;
    padding: 1rem;
    background-color: ${(props) => props.theme.white};

    @media ${device.laptop} {
        margin: 1rem 4rem;
        padding: 1rem 2.5rem;
    }
`;

const InputGroup = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 0.4rem 0;
`;

const DealersContainer = styled(InputGroup)`
    min-height: 1rem;
`;

const Input = styled.input`
    font-family: "nouvelr-regular";
    padding: 0.6rem 1rem;
    width: 100%;
    margin: 0.2rem 0;
    border: 1px solid transparent;
    border-bottom: 1px solid ${(props) => props.theme.medium};

    ::placeholder {
        color: ${(props) => props.theme.medium};
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    [type="number"] {
        -moz-appearance: textfield; /* Firefox */
    }
`;

const StyledSelect = styled.select`
    font-family: "nouvelr-regular";
    padding: 0.8rem 1rem;
    width: 100%;
    margin: 0.2rem 0;

    border: 1px solid transparent;
    border-bottom: 1px solid ${(props) => props.theme.medium};

    cursor: pointer;

    ::placeholder {
        color: ${(props) => props.theme.medium};
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    background-image: url("/icons/down-arrow.svg");
    background-position: 90% center;
    background-repeat: no-repeat;
    background-size: 30px;

    // Hide the default arrow
    ::-ms-expand {
        display: none;
    }
    -moz-appearance: none;
    -webkit-appearance: none;
`;

const Label = styled.label`
    text-align: left;
    margin: 0.2rem 0;
`;

const Submit = styled.button`
    width: 100%;
    margin: 2.5rem 0;
    cursor: pointer;
    padding: 1rem;
    border: 1px solid transparent;
    font-size: 1.15rem;

    background-color: ${(props) => props.theme.primary};

    &:disabled {
        background-color: ${(props) => props.theme.medium};
    }
`;

const StyledErrorMessage = styled.div`
    position: absolute;
    bottom: -10px;
    font-size: 0.7rem;
    color: #cc0000;
    width: 100%;
    margin-top: 0.25rem;
`;

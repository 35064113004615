import Image from "next/dist/client/image";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { formHelpers } from "../../../../lib/formHelpers";
import { IDealerListData } from "../../../../lib/queries/DealerQuery";
import { TestDriveCarInfo } from "../../../../lib/queries/TestDriveCarInfoQuery";
import { DataLayerPush } from "../../../../lib/tracking/DataLayer";
import Footer from "../../../Footer/Footer";
import FooterModal from "../../../FooterModal";
import TopBanner from "../../TopBanner";
import FormComponent from "./FormComponent";
import FacebookPush from "../../../../lib/tracking/FacebookPush";
import { GetCampaignInfo } from "../../../../lib/form-data/campaign-info";

interface ITestDriveForm {
    showModal: boolean;
    setShowModal: React.SetStateAction<boolean>;
    data: IDealerListData;
    carData?: TestDriveCarInfo;
}

const TestDriveForm = ({
    showModal,
    setShowModal,
    data,
    carData,
}: ITestDriveForm) => {
    const [postNumber, setPostNumber] = useState<string>();
    const fullDealersList = data;
    const [filteredDealers, setFilteredDealers] = useState() as any[];
    const [selectedDealerForm, setSelectedDealerForm] = useState(null);
    const [dealerPreviouslySelected, setDealerPreviouslySelected] =
        useState(false);

    useEffect(() => {
        if (carData && carData.name) {
            DataLayerPush(window, {
                car: carData.name,
                event: "formLoadTestDrive",
            });
            FacebookPush("track", "formLoadTestDrive");
        }
    }, [carData]);

    useEffect(() => {
        if (postNumber) {
            const filteredDealers = formHelpers.getPostalNumber(
                postNumber,
                fullDealersList
            );
            setFilteredDealers(filteredDealers);
            setDealerPreviouslySelected(true);
        }
    }, [postNumber, fullDealersList]);

    const campaign = GetCampaignInfo();
    let leasing = false;

    if (
        campaign?.Source.includes("newsletter") &&
        campaign?.Campaign === "24-9" &&
        carData.slug === "scenic-e-tech-electric"
    )
        leasing = true;

    return (
        <>
            <TopBanner
                title={`Prøvekjør`}
                showModal={showModal}
                setShowModal={setShowModal}
            />
            <ContentWrapper>
                {carData.testDriveImage && (
                    <Background>
                        <Image
                            unoptimized={true}
                            src={carData.testDriveImage}
                            layout="fill"
                            objectFit="cover"
                            quality={90}
                            objectPosition="50% 50%"
                            alt={carData.name}
                            placeholder="blur"
                            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                        />
                    </Background>
                )}
                <FormComponent
                    carData={carData}
                    postNumber={postNumber}
                    setPostNumber={setPostNumber}
                    filteredDealers={filteredDealers}
                    selectedDealerForm={selectedDealerForm}
                    setSelectedDealerForm={setSelectedDealerForm}
                    dealerPreviouslySelected={dealerPreviouslySelected}
                    setDealerPreviouslySelected={setDealerPreviouslySelected}
                />
                {leasing && (
                    <LeasingInfoWrapper>
                        <LeasingTitle>1,95 % fastrente i 3 år</LeasingTitle>
                        <LeasingPrice>Leasing fra kr 2 995,-*</LeasingPrice>
                        <LeasingInfo>
                            Tilbudet inkluderer: vinterhjul og Pack Parking,
                            Driving & Safety Assist.
                        </LeasingInfo>
                        <LeasingDutyText>
                            *Gjelder lagerførte biler med utstyrsnivå Techno
                            EV87 220 hk. Startleie: kr 99 880,-Etabl. geb: kr 4
                            990,-Månedsleie: kr 2 995,-og totalpris: kr 212
                            690,- 3 års bindingstid, kjørelengde 30 000 km. Pris
                            gjelder levering i Oslo. Frakt til lokal forhandler
                            kan tilkomme. Månedsleien kan endres ved
                            utbetalingstidspunktet, hvis det har vært endringer
                            i rente, pris eller avgifter mellom
                            søknadstidspunktet og utbetalingstidspunktet. Leien
                            er beregnet med flytende rente og følger normal
                            renteutvikling i hele leieperioden. I samarbeid med
                            Nordea Finans mfl.
                        </LeasingDutyText>
                    </LeasingInfoWrapper>
                )}
            </ContentWrapper>
            <FooterModal setShowModal={setShowModal} />
            <Footer />
        </>
    );
};

export default TestDriveForm;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const Background = styled.div`
    position: absolute;
    min-height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: -1;
`;

const LeasingInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.white};
    padding: 1rem;
    margin: 0 auto 2rem 5%;
    width: 90%;
    max-width: 850px;
`;

const LeasingTitle = styled.h2`
    font-family: "nouvelr-bold";
`;
const LeasingPrice = styled.p`
    font-family: "nouvelr-bold";
    font-size: 1.1rem;
    padding-top: 5px;
`;
const LeasingInfo = styled.p`
    padding-top: 15px;
`;
const LeasingDutyText = styled.p`
    font-size: 80%;
    padding-top: 5px;
`;

const PdfIcon = ({ fill, width, height }) => {
    return (
        <svg
            version="1.1"
            height={height}
            width={width}
            fill={fill}
            x="0px"
            y="0px"
            viewBox="0 0 60 60"
        >
            <g>
                <path
                    d="M44.938,19.258v29.938H15.059V10.678h21.359C39.718,13.438,42.658,16.378,44.938,19.258z M42.838,47.156V21.297h-8.52
		v-8.58h-17.16v34.438H42.838z M21.899,36.417v-8.58h2.939c1.681,0,2.7,1.08,2.7,2.76c0,1.8-0.96,2.76-2.7,2.76h-1.26v3.06H21.899z
		 M23.579,29.337v2.52h1.26c0.66,0,1.021-0.48,1.021-1.26c0-0.9-0.3-1.26-1.021-1.26H23.579z M33.478,27.597v8.82h-1.439v-0.48
		c-0.48,0.36-1.021,0.6-1.62,0.6c-1.5,0-2.22-1.08-2.22-3.18c0-2.34,0.84-3.36,2.88-3.36c0.3,0,0.54,0.06,0.84,0.12v-2.52H33.478z
		 M31.918,34.557v-3c-0.3-0.06-0.54-0.12-0.84-0.12c-0.96,0-1.32,0.48-1.32,1.92c0,1.26,0.24,1.68,1.021,1.68
		C31.138,35.037,31.498,34.917,31.918,34.557z M35.518,36.417v-4.98h-1.08v-1.38h1.08v-0.42c0-1.44,0.66-2.16,1.92-2.16
		c0.42,0,0.84,0.06,1.2,0.18v1.32c-0.3-0.12-0.6-0.18-0.84-0.18c-0.54,0-0.72,0.18-0.72,0.9v0.36h1.56v1.38h-1.56v4.98H35.518z
		 M42.298,19.317c-1.92-2.22-3.96-4.2-5.94-5.879v5.879H42.298z"
                />
            </g>
        </svg>
    );
};

export default PdfIcon;
